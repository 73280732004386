import { FaPhone } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { formatPhone } from "../pages/doctor/utils";
import AppContext from "../AppContext";
import { useContext, useState } from "react";
import { Modal, Button, Group } from "@mantine/core";

export function Phone({ mobile, name, email }) {
    const {context, activeKeypad, setCountryValue, setInputValue, setChat} = useContext(AppContext);
    const can_see_numbers = context?.user?.can_see_numbers;
    const [opened, setOpened] = useState(false);
    
    function setNumberOnKeypad(mobile) {
        if (!can_see_numbers) {
            setOpened(true);
            return;
        }
        activeKeypad();
        setCountryValue(`${mobile[0]}`);
        setInputValue(mobile.slice(1));
    }

    function handleConfirmCall() {
        // Dispatch custom event with the phone number
        const event = new CustomEvent('makeCall', { 
            detail: { number: mobile }
        });
        window.dispatchEvent(event);
        setOpened(false);
    }

    function openChatBox() {
        setChat({name, mobile, email});
    }

    return !mobile ? '---' : <div className="phone">
            <a href={"tel:+" + (can_see_numbers ? mobile : '**********')} target="_blank">
                {can_see_numbers ? formatPhone(mobile) : ''}
            </a>
            <FaMessage
                className="sms-icon"
                onClick={() => openChatBox()}
            />
            {context?.user?.available_for_call ? (
                <FaPhone
                    className="phone-icon"
                    onClick={() => setNumberOnKeypad(mobile)}
                />
            ) : null}
            <Modal opened={opened} onClose={() => setOpened(false)} title="Confirm Call">
                <div>
                    <p>Are you sure you want to make this call?</p>
                    <Group position="right" mt="md">
                        <Button variant="outline" onClick={() => setOpened(false)}>Cancel</Button>
                        <Button onClick={handleConfirmCall}>Confirm</Button>
                    </Group>
                </div>
            </Modal>
        </div>
}