import {
  Avatar,
  Badge,
  Button,
  Card,
  Divider,
  FileInput,
  Grid,
  Group,
  List,
  Modal,
  ScrollArea,
  Select,
  Table,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios2";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/OrderDetails.module.css";
import OrderSteps from "../../components/OrderSteps";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { confirmAlert } from "react-confirm-alert";
import { notifications, showNotification } from "@mantine/notifications";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaPencilAlt,
  FaPhone,
  FaTicketAlt,
} from "react-icons/fa";
import OrderStatus from "../../components/OrderStatus";
import Videocall from "../patient/Videocall";
import AppContext from "../../AppContext";
import VonageClient from "@vonage/client-sdk";
import { FaMessage } from "react-icons/fa6";
import ChatBox from "./ChatBox";
import { Phone } from "../../components/Phone";

function OrderDetails() {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [comments, setComments] = useState([]);
  const [messages, setMessages] = useState([]);
  const [calls, setCalls] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [selectedRcom, setSelectedRecom] = useState();
  const [file, setFile] = useState();
  const userData = useContext(AppContext).context?.user;
  const [chatBoxOpen, setChatBoxOpen] = useState(false);
  const site_id = order?.site_id;
  const { setChat } = useContext(AppContext);

  const [viewRecomOpened, viewRecomModal] = useDisclosure(false);
  const [noteModalOpened, noteModal] = useDisclosure(false);
  const [revokeModalOpened, revokeModal] = useDisclosure(false);

  const [note, setNote] = useState("");
  const [selectedStep, setSelectedStep] = useState();
  const {
    keypad,
    activeKeypad,
    inputValue,
    setInputValue,
    countryValue,
    setCountryValue,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const vonageClient = new VonageClient();
  console.log(vonageClient);

  const updateOrder = (order) => {
    setOrder(order);
    setRecommendations(order.recommendations);
  };
  function setNumberOnKeypad() {
    activeKeypad();
    setCountryValue(`+${order.user.mobile[0]}`);
    setInputValue(order.user.mobile.slice(1));
    console.log(order.user.mobile[0]);
  }
  const commentform = useForm({
    initialValues: {
      comment: "",
    },
    validate: {
      comment: (value) =>
        value.length > 3 ? null : "Please enter 3 chars at least",
    },
  });
  // const recomform = useForm({
  //   initialValues: {
  //     dr_note: '',
  //     valid_for: 12,
  //     limit_exemption: '',
  //     file: null,
  //   },
  //   validate: {
  //   },
  // });

  const revokeform = useForm({
    initialValues: {
      reason: "",
    },
    validate: {},
  });

  // const caregiverform = useForm({
  //   initialValues: {
  //     name: '',
  //     identification: '',
  //     file: null,
  //   },
  //   validate: {
  //   },
  // });

  const addComment = (values) => {
    setLoading(true);
    axios
      .post("/orders/" + id + "/comments", {
        comment: values["comment"],
      })
      .then((res) => {
        setComments([res.data.data, ...comments]);
        commentform.reset();
      })
      .finally(() => setLoading(false));
  };
  
  // const addRecommendation = (values) => {
  //   const formData = new FormData();
  //   Object.keys(values).map((key) =>
  //     formData.append(key, values[key])
  //   )
  //   formData.append('order_id', order.id);
  //   if (!values.file) formData.delete('file');
  //   setLoading(true);
  //   axios.post('/recommendations', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   })
  //     .then((res) => {
  //       setRecommendations([...recommendations, res.data.data])
  //       recomform.reset();
  //     }).finally(() => setLoading(false));
  // }

  useEffect(() => {
    getOrder();
    getComments();
    if (order?.user?.mobile) {
      getMessages();
      getCalls();
    }
  }, [order?.user?.mobile]);

  const getOrder = () => {
    axios.get("orders/" + id).then((res) => {
      const order = res.data.data;
      setOrder(order);
      setRecommendations(order.recommendations);
      //recomform.setValues({ limit_exemption: order.plan.exemption_limit, valid_for: order.plan.valid_for })
      //caregiverform.setValues({ name: order.user.caregiver_name, identification: order.user.caregiver_identification })
    });
  };

  const getComments = () => {
    axios.get("orders/" + id + "/comments").then((res) => {
      setComments(res.data.data);
    });
  };
  const getMessages = () => {
    axios.get(`messages?number=${order.user.mobile}`).then((res) => {
      setMessages(res.data.data);
    });
  };
  const getCalls = () => {
    axios.get(`calls?number=${order.user.mobile}`).then((res) => {
      setCalls(res.data.data);
    });
  };
  const showRecom = (file) => {
    setFile(file);
    viewRecomModal.open();
  };

  const revokeRecom = (recom) => {
    revokeModal.open();
    setSelectedRecom(recom);
  };

  const sendRevokeForm = (values) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to revoke recommendation?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            axios
              .post("recommendations/" + selectedRcom.id + "/revoke", {
                reason: values.reason,
              })
              .then((resp) => {
                setRecommendations(resp.data.data);
                revokeModal.close();
              })
              .finally(() => setLoading(false));
          },
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const idTypeTitle = (type) => {
    if (type == "passport") return "U.S. Passport";
    if (type == "idcard") return "ID Card";
    if (type == "tribal") return "Tribal ID Card";
    if (type == "driver_license") return "Driver's License";
  };

  const sendRecom = (recom) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to send recommendation?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            axios
              .post("recommendations/" + recom.id + "/send")
              .then((resp) => {
                showNotification({
                  title: "Sent",
                  message: "The recommendation has been sent.",
                  color: "green",
                  icon: <FaCheckCircle />,
                });
              })
              .finally(() => setLoading(false));
          },
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const downloadRecom = (file) => {
    setFile(file);
    viewRecomModal.open();
  };

  const editNote = (step) => {
    setNote(step.note);
    setSelectedStep(step);
    noteModal.open();
  };

  const saveNote = () => {
    setLoading(true);
    axios
      .post("/steps/" + selectedStep.pivot_id, {
        note: note,
      })
      .then((res) => {
        setOrder(res.data.data);
        showNotification({
          title: "Saved",
          message: "Condultation note updated.",
          color: "green",
          icon: <FaCheckCircle />,
        });
        noteModal.close();
      })
      .finally(() => setLoading(false));
  };

  // const addCaregiver = (recom) => {
  //   setSelectedRecom(recom);
  //   caregiverModal.open();
  // }

  // const sendCaregiverForm = (values) => {
  //   const formData = new FormData();
  //   Object.keys(values).map((key) =>
  //     formData.append(key, values[key])
  //   )
  //   if (!values.file) formData.delete('file');
  //   setLoading(true);
  //   axios.post('/recommendations/'+ selectedRcom.id + '/caregiver', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   })
  //     .then((res) => {
  //       setRecommendations(res.data.data)
  //       caregiverform.reset();
  //       caregiverModal.close();
  //     }).finally(() => setLoading(false));
  // }

  const openChatBox = () => {
    setChat({
      name: order.user.name,
      mobile: order.user.mobile,
      email: order.user.email
    });
    setChatBoxOpen(true);
  };

  return (
    <>
      {!order.id ? (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <Grid>
          <Grid.Col>
            <Grid>
              {order.current_step &&
                order.current_step.type == "videocall" &&
                1 && (
                  <Grid.Col span={{ lg: 12 }}>
                    <Videocall is_doctor={true}></Videocall>
                  </Grid.Col>
                )}
              <Grid.Col span={{ lg: 6 }}>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <Group mb={10}>
                      <Avatar
                        radius="xl"
                        src={order.user.profile_pic}
                        alt="it's me"
                      />
                      <strong>{order.user.name}</strong>
                      <br />
                    </Group>
                    <div className={styles.infoWrapper}>
                      <div className={styles.infoRow}>
                        <div
                          style={{
                            color: "darkred",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Patient ID: {order.user.id}
                        </div>
                      </div>
                      <div
                        className={styles.infoRow}
                        style={{
                          fontSize: "1.2em",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <strong>Name: </strong>
                        {order.user.name}
                      </div>
                      <div
                        className={styles.infoRow}
                        style={{
                          fontSize: "1.2em",
                          fontWeight: "bold",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <strong>Phone: </strong>
                        <Phone mobile={order.user.mobile} email={order.user.email} name={order.user.name}/>
                      </div>
                      <div className={styles.infoRow}>
                        <strong>Order ID: </strong>
                        {order.id}
                      </div>
                      <div className={styles.infoRow}>
                        <strong>DoB: </strong>
                        <Moment format="MM/DD/YYYY">
                          {order.user.dob}
                        </Moment>{" "}
                        (Age: {moment().diff(order.user.dob, "years", false)})
                      </div>
                      <div className={styles.infoRow}>
                        <strong>Gender: </strong>
                        {order.user.gender}
                      </div>
                      <div className={styles.infoRow}>
                        <strong>Email: </strong>
                        {order.user.email}
                      </div>
                      {site_id !== 2 && (
                        <div className={styles.infoRow}>
                          <strong>Driver's License: </strong>
                          {order?.user?.driver_license_number}
                        </div>
                      )}
                      {order?.plan?.has_caregiver === 1 ? (
                        <>
                          <div className={styles.infoRow}>
                            <strong>Caregiver Name: </strong>
                            {order.user.caregiver_name}
                          </div>
                          <div className={styles.infoRow}>
                            <strong>Caregiver ID: </strong>
                            {order.user.caregiver_identification}
                          </div>
                        </>
                      ) : (
                        <div>No order plan</div>
                      )}
                      <div className={styles.infoRow}>
                        <strong>Address: </strong>
                        {order.user.address}
                      </div>
                      <div className={styles.infoRow}>
                        <strong>City: </strong>
                        {order.user.city}
                      </div>
                      <div className={styles.infoRow}>
                        <strong>State: </strong>
                        {order.user.state_name}
                      </div>
                      <div className={styles.infoRow}>
                        <strong>Zip Code: </strong>
                        {order.user.zipcode}
                      </div>

                      {order.state_id == 45 && site_id != 2 && (
                        <div
                          className={styles.infoRow}
                          style={{ color: "red" }}
                        >
                          <strong>Pennsylvania ID: </strong>
                          {order.user.pid}
                        </div>
                      )}

                      <div className={styles.infoRow}>
                        <strong>Signature</strong>
                        <br />
                        <a href={order.user.signature} target="_blank">
                          <img
                            src={order.user.signature}
                            style={{ maxWidth: "200px" }}
                          />
                        </a>
                      </div>

                      {site_id == 2 &&
                        order.pets.map((pet, index) => (
                          <>
                            <div className={styles.infoRow}>
                              <h5>Pet {index + 1}</h5>
                              <strong>Name:</strong> {pet.name}
                              <br />
                              <strong>Breed:</strong> {pet.breed}
                              <br />
                              <strong>Type:</strong> {pet.type}
                              <br />
                              <strong>Age:</strong> {pet.age}
                              <br />
                              <a href={pet.image} target="_blank">
                                <img
                                  src={pet.image}
                                  style={{ maxWidth: "200px" }}
                                />
                              </a>
                              <Divider size="sm" />
                            </div>
                          </>
                        ))}

                      {site_id != 2 && (
                        <>
                          <div className={styles.infoRow}>
                            <strong>ID Proof</strong> (
                            {idTypeTitle(order.user.id_proof_type)})<br />
                            <a href={order.user.id_proof} target="_blank">
                              <img
                                src={order.user.id_proof}
                                style={{ maxWidth: "200px" }}
                              />
                            </a>
                          </div>

                          {order.user.medical_record && (
                            <div className={styles.infoRow}>
                              <strong>Medical Record</strong>
                              <br />
                              <a
                                href={order.user.medical_record}
                                target="_blank"
                              >
                                <img
                                  src={order.user.medical_record}
                                  style={{ maxWidth: "200px" }}
                                />
                              </a>
                            </div>
                          )}

                          <div className={styles.infoRow}>
                            <strong>Allergies?: </strong>
                            {order.user.allergies}
                          </div>

                          <div style={{ padding: "20px" }}>
                            <strong>Medical Conditions:</strong>
                            <ul style={{ marginLeft: "30px" }}>
                              {order.user.medical_conditions.map((i) => (
                                <li>{i.title}</li>
                              ))}
                              {order.user.medical_conditions.length == 0 && (
                                <li>Nothing</li>
                              )}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </Card.Section>
                </Card>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <h4>{site_id == 2 ? "ESA Letters" : "Recommendations"}</h4>
                    {/* {order.state.show_recom_form && <form onSubmit={recomform.onSubmit(addRecommendation)}>
                    <Select
                      label="Valid for"
                      placeholder="Valid for"
                      defaultValue={12}
                      {...recomform.getInputProps('valid_for')}
                      data={[
                        { value: 1, label: '1 Month' },
                        { value: 2, label: '2 Months' },
                        { value: 3, label: '3 Months' },
                        { value: 4, label: '4 Months' },
                        { value: 5, label: '5 Months' },
                        { value: 6, label: '6 Months' },
                        { value: 7, label: '7 Months' },
                        { value: 8, label: '8 Months' },
                        { value: 9, label: '9 Months' },
                        { value: 10, label: '10 Months' },
                        { value: 11, label: '11 Months' },
                        { value: 12, label: '12 Months' },
                        { value: 13, label: '13 Months' },
                        { value: 14, label: '14 Months' },
                        { value: 15, label: '15 Months' },
                        { value: 16, label: '16 Months' },
                        { value: 17, label: '17 Months' },
                        { value: 18, label: '18 Months' },
                        { value: 19, label: '19 Months' },
                        { value: 20, label: '20 Months' },
                        { value: 21, label: '21 Months' },
                        { value: 22, label: '22 Months' },
                        { value: 23, label: '23 Months' },
                        { value: 24, label: '24 Months' },
                      ]}
                    ></Select>
                    <TextInput
                      label="Limit Exemption"
                      {...recomform.getInputProps('limit_exemption')
                      } ></TextInput>
                    {!order.generate_recom && <FileInput
                      placeholder="Pick file"
                      label="Recommendation"
                      {...recomform.getInputProps('file')}
                      withAsterisk
                    />}
                    <Textarea
                      placeholder="Dr. note"
                      label="Your comment"
                      {...recomform.getInputProps('dr_note')}
                    />
                    <Button type="submit" loading={loading}>Add</Button>
                  </form>} */}
                    <div style={{ marginTop: "1em" }}>
                      {recommendations.length > 0 &&
                        recommendations.map((r) => (
                          <div
                            className={[
                              styles.recom,
                              r.revoke_date ? styles.revoked : "",
                            ]
                              .filter((s) => !!s)
                              .join(" ")}
                          >
                            <div>
                              <strong>
                                {site_id == 2 ? "ESA Letter" : "Recommendation"}{" "}
                                ID:
                              </strong>{" "}
                              {r.recom_id}
                            </div>
                            <div>
                              <strong>Expire Date:</strong>{" "}
                              {moment(r.expire_date).format("MM-DD-YYYY")} (
                              {moment(r.expire_date).diff(
                                moment(),
                                "days",
                                false
                              )}{" "}
                              days)
                            </div>
                            <div>
                              <strong>Isuue Date:</strong>{" "}
                              {moment(r.issue_date).format("MM-DD-YYYY")}
                            </div>
                            <div>
                              <strong>Dr. Note:</strong>{" "}
                              {r.dr_note ? r.dr_note : "---"}{" "}
                            </div>
                            <Group mt={10}>
                              {r.file && (
                                <Button
                                  variant="light"
                                  color="blue"
                                  size="xs"
                                  onClick={() => showRecom(r.file)}
                                >
                                  View
                                </Button>
                              )}
                              {!r.revoke_date && (
                                <>
                                  <Button
                                    variant="light"
                                    color="cyan"
                                    size="xs"
                                    onClick={() => revokeRecom(r)}
                                  >
                                    Revoke
                                  </Button>
                                  <Button
                                    variant="light"
                                    color="grape"
                                    size="xs"
                                    onClick={() => sendRecom(r)}
                                  >
                                    Send
                                  </Button>
                                  {r.file && (
                                    <Button
                                      variant="light"
                                      color="green"
                                      size="xs"
                                      onClick={() => downloadRecom(r.file)}
                                    >
                                      Download
                                    </Button>
                                  )}
                                  {r.file2 && (
                                    <Button
                                      variant="light"
                                      color="blue"
                                      size="xs"
                                      onClick={() => downloadRecom(r.file2)}
                                    >
                                      Download Attached File
                                    </Button>
                                  )}
                                </>
                              )}
                              {/* {!r.caregiver && order.plan.has_caregiver && <Button variant="light" color="grape" size="xs" onClick={() => addCaregiver(r)}>Add Caregiver</Button>} */}
                            </Group>
                            {r.caregiver && (
                              <div className={styles.caregiver}>
                                <div>
                                  <strong>Caregiver Name:</strong>{" "}
                                  {r.caregiver.name}
                                </div>
                                <div>
                                  <strong>Identification:</strong>{" "}
                                  {r.caregiver.identification}
                                </div>
                                <Group mt={10}>
                                  <Button
                                    variant="light"
                                    color="blue"
                                    loading={loading}
                                    size="xs"
                                    onClick={() => showRecom(r.caregiver.file)}
                                  >
                                    View
                                  </Button>
                                </Group>
                              </div>
                            )}
                          </div>
                        ))}
                      {recommendations.length == 0 && (
                        <p style={{ textAlign: "center" }}>
                          No {site_id == 2 ? "ESA Letters" : "Recommendations"}
                        </p>
                      )}
                    </div>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={{ lg: 6 }}>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  {!order.plan && (
                    <Card.Section p={10}>
                      <>
                        <h4>Order Info</h4>
                        <h5>No plan</h5>
                        <div className={styles.infoWrapper}>
                          <div className={styles.infoRow}>
                            <strong>Date: </strong>
                            <Moment format="MM/DD/YYYY">
                              {order.created_at}
                            </Moment>
                            {moment().diff(order.created_at, "hours", false)}{" "}
                            hours ago
                          </div>
                        </div>
                        <div className={styles.infoRow}>
                          <strong>Dispensary: </strong>
                          {order.partner?.title ?? "---"}
                        </div>
                        <div className={styles.infoRow}>
                          <strong>Status: </strong>
                          <OrderStatus status={order.status}></OrderStatus>
                        </div>
                      </>
                    </Card.Section>
                  )}
                  {order?.plan && (
                    <Card.Section p={10}>
                      <h4>Order Info</h4>
                      <div className={styles.infoWrapper}>
                        <div className={styles.infoRow}>
                          <strong>Date: </strong>
                          <Moment format="MM/DD/YYYY">
                            {order.created_at}
                          </Moment>{" "}
                          ({moment().diff(order.created_at, "hours", false)}{" "}
                          hours ago)
                        </div>
                        {order.plan ? (
                          <div className={styles.infoRow}>
                            <strong>Plan name: </strong>
                            {order.plan != null && order.plan_name
                              ? order.plan_name
                              : "no plan"}
                          </div>
                        ) : (
                          <p>No plan</p>
                        )}
                        <div className={styles.infoRow}>
                          <strong>Dispensary: </strong>
                          {order.partner?.title ?? "---"}
                        </div>
                        <div className={styles.infoRow}>
                          <strong>Valid for: </strong>
                          {order.plan != null && order?.plan.valid_for
                            ? "months"
                            : "-"}
                        </div>
                        {!!order.plan.has_id_card && (
                          <div className={styles.infoRow}>
                            <strong>+ ID CARD: </strong>
                            {order.has_id_card ? "Yes" : "No"}
                          </div>
                        )}
                        {!!order.plan.support_additional_pets && (
                          <div className={styles.infoRow}>
                            <strong>+ ADDITIONAL PET: </strong>
                            {order.additional_pet ? "Yes" : "No"}
                          </div>
                        )}
                        {/* <div className={styles.infoRow}>
                      <strong>Total Amount: </strong>
                      {order.total_amount}$
                    </div>
                    {order.discount_amount > 0 && <div className={styles.infoRow}>
                      <strong>Discount Amount: </strong>
                      -{order.discount_amount}$ {order.coupon_code ? '(' + order.coupon_code + ')' : ''}
                    </div>}
                    <div className={styles.infoRow}>
                      <strong>Paid Amount: </strong>
                      {order.paid_amount}$
                    </div> */}
                        <div className={styles.infoRow}>
                          <strong>Status: </strong>
                          <OrderStatus status={order.status}></OrderStatus>
                        </div>
                      </div>
                      {order.current_step && (
                        <>
                          <Divider></Divider>
                          <OrderSteps
                            order={order}
                            is_doctor={true}
                            updateOrder={updateOrder}
                          ></OrderSteps>
                          <div style={{ padding: "1em" }}>
                            {/* {order.status == 'processing' && <div dangerouslySetInnerHTML={{ __html: order.current_step.dr_decription }}></div>} */}
                            {/* <Group position='center' mt={10}>
                        {order.status == 'processing' && <Button onClick={() => showDoneModal('done')}>Mark Done</Button>}
                        {order.status == 'processing' && <Button color="red" onClick={() => showDoneModal('unapprove')}>Unapprove</Button>}
                      </Group> */}
                          </div>
                        </>
                      )}
                    </Card.Section>
                  )}
                </Card>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <h4>Consultation Notes</h4>
                    <div>
                      {order.steps &&
                        order.steps
                          .filter((s) => !!s.done_at)
                          .map((s) => (
                            <div className={styles.comment}>
                              <div className={styles.date}>
                                {s.done_by} at{" "}
                                {moment(s.done_at).format(
                                  "MM-DD-YYYY HH:mm:ss"
                                )}
                              </div>
                              <div>{s.note ?? "---"}</div>
                              <div
                                style={{ textAlign: "right", fontSize: "11px" }}
                              >
                                {s.done_by_id == userData.id && (
                                  <a
                                    className="clickable"
                                    onClick={() => editNote(s)}
                                  >
                                    <FaPencilAlt /> edit
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                      {order.steps &&
                        order.steps.filter((s) => !!s.done_at).length == 0 && (
                          <p style={{ textAlign: "center" }}>---</p>
                        )}
                    </div>
                  </Card.Section>
                </Card>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <h4>Comments</h4>
                    <form onSubmit={commentform.onSubmit(addComment)}>
                      <Textarea
                        placeholder="Your comment"
                        label="Your comment"
                        autosize
                        className={styles.resize}
                        minRows={5}
                        withAsterisk
                        {...commentform.getInputProps("comment")}
                      />
                      <Button type="submit" loading={loading}>
                        Save
                      </Button>
                    </form>
                    <div>
                      {comments.length > 0 &&
                        comments.map((c) => (
                          <div className={styles.comment}>
                            <div className={styles.date}>
                              by {c.comment_by} at{" "}
                              {moment(c.created_at).format(
                                "MM-DD-YYYY HH:mm:ss"
                              )}
                            </div>
                            <div>{c.comment}</div>
                            {/* {c.commented_by_id == userData.id && <Button size="xs" onClick={() => editComment(c)}>Edit</Button>} */}
                          </div>
                        ))}
                    </div>
                  </Card.Section>
                </Card>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <h4>Call logs</h4>
                    {calls.length > 0 ? (
                      <CallLogsTable calls={calls} getOrder={getCalls} />
                    ) : (
                      <p style={{ textAlign: "center" }}>No calls found</p>
                    )}
                  </Card.Section>
                </Card>
                {order.questions && order.questions.length > 0 && (
                  <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                    <Card.Section p={10}>
                      <h4>Questionnaire</h4>
                      <List m={10}>
                        {order.questions.map((q) => (
                          <List.Item key={q.id}>
                            {q.question}: <strong>{q.answer}</strong>{" "}
                          </List.Item>
                        ))}
                      </List>
                    </Card.Section>
                  </Card>
                )}
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <div className={styles.chatHeader}>
                      <h4>Text Messages</h4>
                      <Button 
                        variant="light"
                        leftSection={<FaMessage size={16} />}
                        onClick={openChatBox}
                        color="blue"
                        size="sm"
                        radius="xl"
                      >
                        Start Chat
                      </Button>
                    </div>
                    <ScrollArea h={400}>
                      {messages.length > 0 ? (
                        <div className={styles.messagesList}>
                          {messages.map((msg) => (
                            <div 
                              key={msg.id} 
                              className={`${styles.messageItem} ${msg.received ? styles.received : styles.sent}`}
                            >
                              <div className={styles.messageContent}>
                                <p>{msg.message}</p>
                                <small>
                                  {moment(msg.created_at).format("MM/DD/YYYY HH:mm")}
                                </small>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p style={{ textAlign: "center" }}>No messages found</p>
                      )}
                    </ScrollArea>
                  </Card.Section>
                </Card>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      )}
      <Modal
        opened={viewRecomOpened}
        size="xl"
        onClose={viewRecomModal.close}
        title="View Recommendation"
      >
        <embed
          src={
            site_id == 2 && file
              ? file.replace("admin.onlinemedicalcard", "api2.myesadoctor")
              : file
          }
          frameborder="0"
          width="100%"
          height="400px"
        ></embed>
      </Modal>

      {/* <Modal opened={caregiverModalOpened} size="lg" onClose={caregiverModal.close} title="Add caregiver">
      <form onSubmit={caregiverform.onSubmit(sendCaregiverForm)}>
        <TextInput
          label="Caregiver Name"
          {...caregiverform.getInputProps('name')
          } ></TextInput>
        <TextInput
          label="Caregiver Identification"
          {...caregiverform.getInputProps('identification')
          } ></TextInput>
        {!order.generate_recom && <FileInput
          placeholder="Pick a file"
          label="Caregiver Recommendation File"
          {...caregiverform.getInputProps('file')}
          withAsterisk
        />}
        <Button type="submit" loading={loading}>Add</Button>
      </form>
    </Modal> */}

      <Modal
        opened={noteModalOpened}
        size="lg"
        onClose={noteModal.close}
        title="Edit Condultation Note"
      >
        <Textarea
          label="Note"
          value={note}
          onChange={(event) => setNote(event.currentTarget.value)}
        ></Textarea>
        <Button onClick={() => saveNote()} loading={loading}>
          Save
        </Button>
      </Modal>

      <Modal
        opened={revokeModalOpened}
        size="lg"
        onClose={revokeModal.close}
        title="Revoke"
      >
        <form onSubmit={revokeform.onSubmit(sendRevokeForm)}>
          <Textarea
            placeholder="Why this recommendation revoked?"
            label="Reason"
            withAsterisk
            {...revokeform.getInputProps("reason")}
          />
          <Button type="submit" color="red" loading={loading}>
            Revoke
          </Button>
        </form>
      </Modal>
    </>
  );
}

export default OrderDetails;

function CallLogsTable({ calls, getOrder }) {
  const rows = calls.map((call) => {
    return <CallLogRow call={call} getOrder={getOrder} />;
  });
  return (
    <ScrollArea>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Date</Table.Th>
            <Table.Th>From</Table.Th>
            <Table.Th>To</Table.Th>
            <Table.Th>Duration</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Note</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea>
  );
}

function CallLogRow({ call, getOrder }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [editedNote, setEditedNote] = useState(call.note);
  const [saving, setSaving] = useState(false);
  const { context } = useContext(AppContext);

  function editNote() {
    setSaving(true);
    try {
      axios.post("call-note", { note: editedNote, log_id: call.id }).then(
        (res) => {
          setSaving(false);
          close();
          getOrder();
          notifications.show({
            message: "Call note has been edited successfully",
          });
        }
      );
    } catch (error) {
      notifications.show({ message: error.response.data.message });
      setSaving(false);
    }
  }

  return (
    <Table.Tr key={call.id}>
      <Table.Td>
        {moment(call.created_at).format("MMMM Do YYYY HH:mm:ss")}
      </Table.Td>
      <Table.Td>{context?.user?.can_see_numbers ? call.from : '***'}</Table.Td>
      <Table.Td>{context?.user?.can_see_numbers ? call.to : '***'}</Table.Td>
      <Table.Td>{call.duration} seconds</Table.Td>
      <Table.Td>
        <Badge color={call.status === 'completed' ? 'green' : 'red'}>
          {call.status}
        </Badge>
      </Table.Td>
      <Table.Td>
        <Button 
          variant="light" 
          color="blue" 
          size="xs"
          onClick={open}
        >
          {call.note ? 'Edit' : 'Add'} Note
        </Button>
      </Table.Td>
      <Modal
        opened={opened}
        onClose={close}
        title="Call Note"
        size="md"
      >
        <Textarea
          value={editedNote}
          onChange={(e) => setEditedNote(e.target.value)}
          placeholder="Enter call note..."
          minRows={3}
        />
        <Group mt="md" justify="flex-end">
          <Button onClick={editNote} loading={saving}>
            Save
          </Button>
        </Group>
      </Modal>
    </Table.Tr>
  );
}
