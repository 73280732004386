import {
  Badge,
  Button,
  Card,
  FileInput,
  Grid,
  Group,
  Modal,
  Select,
  Textarea,
  TextInput
} from "@mantine/core";
import { DatesProvider, DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import VonageClient from "@vonage/client-sdk";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  FaPencilAlt,
  FaPhone,
  FaPlus,
  FaUpload
} from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import AppContext from "../../AppContext";
import axios from "../../axios2";
import styles from "../../styles/OrderDetails.module.css";
import ChatBox from "./ChatBox";
import { Phone } from "../../components/Phone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
function LeadDetails() {
  const { id } = useParams();
  const [lead, setLead] = useState({});
  const [notes, setNotes] = useState([]);
  const [files, setFiles] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedNote, setSelectedNote] = useState();
  const [chatBoxOpen,setChatBoxOpen] = useState(false)
  const userData = useContext(AppContext).context?.user;

  const [doctors, setDoctors] = useState([]);

  const {
    keypad,
    activeKeypad,
    inputValue,
    setInputValue,
    countryValue,
    setCountryValue,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const vonageClient = new VonageClient();

  function setNumberOnKeypad() {
    activeKeypad();
    setCountryValue(`${lead.mobile[0]}`);
    setInputValue(lead.mobile.slice(1));
  }

  const noteForm = useForm({
    initialValues: {
      note: "",
    },
    validate: {
      note: (value) =>
        value.length > 3 ? null : "Please enter 3 chars at least",
    },
  });

  const fileForm = useForm({
    initialValues: {
      file: "",
      description: "",
    },
    validate: {
      description: (value) =>
        value.length < 3 && "Please write at least 3 characters",
    },
  });

  const leadForm = useForm({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      patient_note: "",
      status: "",
    },
    validate: {
      name: (value) => value.length < 3 && "Please write at least 3 characters",
      mobile: (value) =>
        value.length < 3 && "Please write at least 3 characters",
      email: (value) =>
        value.length < 3 && "Please write at least 3 characters",
      status: (value) =>
        value.length < 3 && "Please write at least 3 characters",
    },
  });

  const appointmentForm = useForm({
    initialValues: {
      due_date: "",
      title: "",
      note: "",
      status: "planned",
      dr_id: '',
    },
    validate: {
      status: (value) =>
        value.length < 3 && "Please write at least 3 characters",
      due_date: (value) =>
        value.length < 3 && "Please write at least 3 characters",
    },
  });

  const [appointmentModalOpened, appointmentModal] = useDisclosure(false);
  const [noteModalOpened, noteModal] = useDisclosure(false);
  const [fileModalOpened, fileModal] = useDisclosure(false);
  const [leadModalOpened, leadModal] = useDisclosure(false);

  useEffect(() => {
    getLead();
    axios.get('/steadycare-leads/doctors').then(resp => setDoctors(resp.data.data));
  }, []);

  const getLead = () => {
    axios.get("steadycare-leads/" + id).then((res) => {
      const lead = res.data.data;
      setLead(lead);
      setAppointments(lead.appointments);
      setNotes(lead.notes);
      setFiles(lead.files);
    });
  };

  const saveAppointment = (data) => {
    setLoading(true);
    data["id"] = selectedAppointment?.id;
    axios
      .post("steadycare-leads/" + lead.id + "/appointments", data)
      .then((res) => {
        getLead();
        appointmentModal.close();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveLead = (data) => {
    setLoading(true);
    axios
      .put("steadycare-leads/" + lead.id, data)
      .then((res) => {
        setLead(res.data.data);
        leadModal.close();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveNote = (data) => {
    setLoading(true);
    data["id"] = selectedNote?.id;
    axios
      .post("steadycare-leads/" + lead.id + "/notes", data)
      .then((res) => {
        getLead();
        noteModal.close();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveFile = (data) => {
    const formData = new FormData();
    formData.append("description", data.description);
    formData.append("file", data.file);

    setLoading(true);
    axios
      .post("steadycare-leads/" + lead.id + "/files", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        getLead();
        fileModal.close();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formatPhone = (phone) => {
    return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2) $3 - $4");
  };

  // const addCaregiver = (recom) => {
  //   setSelectedRecom(recom);
  //   caregiverModal.open();
  // }

  // const sendCaregiverForm = (values) => {
  //   const formData = new FormData();
  //   Object.keys(values).map((key) =>
  //     formData.append(key, values[key])
  //   )
  //   if (!values.file) formData.delete('file');
  //   setLoading(true);
  //   axios.post('/recommendations/'+ selectedRcom.id + '/caregiver', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   })
  //     .then((res) => {
  //       setRecommendations(res.data.data)
  //       caregiverform.reset();
  //       caregiverModal.close();
  //     }).finally(() => setLoading(false));
  // }

  return (
    <>
      {!lead.id ? (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <Grid>
          <Grid.Col>
            <Grid>
              <Grid.Col span={{ lg: 6 }}>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <div className={styles.infoWrapper}>
                      <div className={styles.infoRow}>
                        <div
                          style={{
                            color: "darkred",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Appointment ID: {lead.id} <Badge>{lead.status}</Badge>
                          &nbsp;{" "}
                          <Button
                            size="xs"
                            color="green"
                            onClick={() => {
                              leadForm.setValues({
                                name: lead.name,
                                email: lead.email,
                                mobile: lead.mobile,
                                patient_note: lead.patient_note,
                                status: lead.status,
                              });
                              leadModal.open();
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                      <div
                        className={styles.infoRow}
                        style={{
                          fontSize: "1.2em",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <strong>Next Appointment Date:</strong> <br />
                        {lead.next_appointment
                          ? dayjs(lead.next_appointment.due_date)
                              .tz(process.env.REACT_APP_TIMEZONE)
                              .format("MM/DD/YYYY HH:mm")
                          : "UNSHCEDULED"}
                      </div>
                      <div
                        className={styles.infoRow}
                        style={{
                          fontSize: "1.2em",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <strong>Name: </strong>
                        {lead.name}
                      </div>
                      <div
                        className={styles.infoRow}
                        style={{
                          fontSize: "1.2em",
                          fontWeight: "bold",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <strong>Phone: </strong>
                        <Phone mobile={lead.mobile} email={lead.email} name={lead.name} />                     
                        </div>
                      <div className={styles.infoRow}>
                        <strong>Email: </strong>
                        {lead.email}
                      </div>
                      <div className={styles.infoRow}>
                        <strong>Appointment for: </strong>
                        {lead.appointment_for}
                      </div>
                      <div className={styles.infoRow}>
                        <strong>Patient's note: </strong>
                        {lead.patient_note}
                      </div>
                    </div>
                  </Card.Section>
                </Card>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <h4>
                      Appointments
                      <Button
                        style={{ float: "right" }}
                        variant="outline"
                        color="default"
                        onClick={() => {
                          setSelectedAppointment(null);
                          appointmentModal.open();
                        }}
                      >
                        <FaPlus /> &nbsp; Add Appointment
                      </Button>
                    </h4>
                    <div style={{ marginTop: "1em" }}>
                      {appointments.length > 0 &&
                        appointments.map((r) => (
                          <div
                            key={r.id}
                            className={[styles.recom]
                              .filter((s) => !!s)
                              .join(" ")}
                          >
                            <div>ID: {r.id}</div>
                            <div>
                              <strong>Appointment Date:</strong>{" "}
                              {moment(r.due_date).format("MM-DD-YYYY HH:mm")} (
                              {moment(r.due_date).diff(
                                moment(),
                                "days",
                                false
                              )}{" "}
                              days)
                            </div>
                            <div>
                              <strong>Created at:</strong>{" "}
                              {moment(r.created_at).format("MM-DD-YYYY HH:mm")}
                            </div>
                            <div>
                              <strong>Title:</strong>{" "}
                              {r.title ? r.title : "---"}{" "}
                              <Badge>{r.status}</Badge>
                            </div>
                            <div>
                              <strong>Doctor:</strong>{" "}
                              {r.doctor_name ? r.doctor_name : "---"}{" "}
                            </div>
                            <div>
                              <strong>Note:</strong> {r.note ? r.note : "---"}{" "}
                            </div>
                            <Group mt={10}>
                              <Button
                                variant="light"
                                color="cyan"
                                size="xs"
                                onClick={() => {
                                  appointmentForm.setValues({
                                    due_date: moment(r.due_date).toDate(),
                                    status: r.status,
                                    note: r.note,
                                    title: r.title,
                                    dr_id: r.dr_id,
                                  });
                                  setSelectedAppointment(r);
                                  appointmentModal.open();
                                }}
                              >
                                Edit
                              </Button>
                            </Group>
                          </div>
                        ))}
                      {appointments.length == 0 && (
                        <p style={{ textAlign: "center" }}>
                          No Planned Appointment
                        </p>
                      )}
                    </div>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={{ lg: 6 }}>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <h4>
                      Notes
                      <Button
                        style={{ float: "right" }}
                        variant="outline"
                        color="default"
                        onClick={() => {
                          setSelectedNote(null);
                          noteModal.open();
                        }}
                      >
                        <FaPlus /> &nbsp; Add Note
                      </Button>
                    </h4>
                    <div>
                      {notes &&
                        notes.map((s) => (
                          <div key={s.id} className={styles.comment}>
                            <div className={styles.date}>
                              {moment(s.created_at).format("MM-DD-YYYY HH:mm")}
                            </div>
                            <div>{s.note ?? "---"}</div>
                            <div
                              style={{ textAlign: "right", fontSize: "11px" }}
                            >
                              <a
                                className="clickable"
                                onClick={() => {
                                  setSelectedNote(s);
                                  noteForm.setValues({ note: s.note });
                                  noteModal.open();
                                }}
                              >
                                <FaPencilAlt /> edit
                              </a>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card.Section>
                </Card>
                <Card shadow="sm" p={30} m={10} radius="md" withBorder>
                  <Card.Section p={10}>
                    <h4>
                      Medical Files
                      <Button
                        style={{ float: "right" }}
                        variant="outline"
                        color="default"
                        onClick={() => {
                          fileModal.open();
                        }}
                      >
                        <FaUpload /> &nbsp; Upload
                      </Button>
                    </h4>
                    <div>
                      {files.length > 0 &&
                        files.map((c) => (
                          <div className={styles.comment} key={c.id}>
                            <div className={styles.date}>
                              {moment(c.created_at).format(
                                "MM-DD-YYYY HH:mm:ss"
                              )}
                            </div>
                            <div>
                              {c.description}
                              &nbsp;
                              <a
                                style={{ float: "right", margin: "5px" }}
                                href={c.file}
                                target="_blank"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card.Section>
                </Card>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      )}

      <Modal
        opened={appointmentModalOpened}
        size="lg"
        onClose={appointmentModal.close}
        title="Appointment"
      >
        <form onSubmit={appointmentForm.onSubmit(saveAppointment)}>
          <DatesProvider settings={{ timezone: process.env.REACT_APP_TIMEZONE }}>
            <DateTimePicker
              dropdownType="modal"
              {...appointmentForm.getInputProps("due_date")}
              label="Appointment Date"
              minDate={new Date()} // Prevents selecting past dates
              valueFormat="MM/DD/YYYY HH:mm" // USA date format
              required // Makes it mandatory
            />
            </DatesProvider>
          <Select
            label="Status"
            {...appointmentForm.getInputProps("status")}
            data={["planned", "done", "canceled", "rescheduled", "no_show"]}
          />
          <TextInput
            label="Title"
            {...appointmentForm.getInputProps("title")}
          />
          <Textarea
            label="Note"
            {...appointmentForm.getInputProps("note")}
          ></Textarea>
          <Select
          label='Doctor'
          data={doctors.map(d => ({id: d.id, label: d.name, value: d.id.toString()}))}
          {...appointmentForm.getInputProps("dr_id")}
           />
          <Button type="submit" loading={loading}>
            Save Appointment
          </Button>
        </form>
      </Modal>

      <Modal
        opened={fileModalOpened}
        size="lg"
        onClose={fileModal.close}
        title="Medical Record"
      >
        <form onSubmit={fileForm.onSubmit(saveFile)}>
          <FileInput
            placeholder="Pick a file"
            label="Medical Record File"
            {...fileForm.getInputProps("file")}
            withAsterisk
          />
          <Textarea
            label="Description"
            {...fileForm.getInputProps("description")}
          ></Textarea>
          <Button type="submit" loading={loading}>
            Save Medical File
          </Button>
        </form>
      </Modal>

      <Modal
        opened={noteModalOpened}
        size="lg"
        onClose={noteModal.close}
        title="Medical Record"
      >
        <form onSubmit={noteForm.onSubmit(saveNote)}>
          <Textarea label="Note" {...noteForm.getInputProps("note")}></Textarea>
          <Button type="submit" loading={loading}>
            Save Note
          </Button>
        </form>
      </Modal>

      <Modal
        opened={leadModalOpened}
        size="lg"
        onClose={leadModal.close}
        title="Edit Appointment"
      >
        <form onSubmit={leadForm.onSubmit(saveLead)}>
          <Select
            label="Status"
            {...leadForm.getInputProps("status")}
            data={["new", "scheduled", "waiting", "canceled", "closed", "followup"]}
          />
          <TextInput label="Name" {...leadForm.getInputProps("name")} />
          <TextInput label="Email" {...leadForm.getInputProps("email")} />
          <TextInput label="Mobile" {...leadForm.getInputProps("mobile")} />
          <Textarea
            label="Patient Note"
            {...leadForm.getInputProps("patient_note")}
          ></Textarea>
          <Button type="submit" loading={loading}>
            Save
          </Button>
        </form>
      </Modal>
    </>
  );
}

export default LeadDetails;
