import $ from "jquery";
import "jquery-validation";
import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Label from "../../config/DoctorLabel";
import Axios from "../../axios";
import Moment from "react-moment";
import "moment-timezone";
import Helpers from "../../components/doctor/Helper";
import Comments from "../../components/doctor/Comments";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import { FaSpinner } from "react-icons/fa";

function Patientprofile() {
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState([]);
  const [plansInfo, setPlansInfo] = useState([]);
  const [recInfo, setRecInfo] = useState([]);
  const [recList, setRecList] = useState([]);
  const SessionToken = window.sessionStorage.getItem("token");
  const [loadingRButton, setloadingRButton] = useState(0);
  const [loadingRecButton, setloadingRecButton] = useState(0);
  const [loaderStatus, setloaderStatus] = useState(0);
  const [isOthers,setIsothers]=useState(0);
  const [limitExp,setLimitExp]=useState('');
  const [planId,setPlanId]=useState('');
  const [tansNumber,setTransNumber]=useState('');
  const [customPrice,setCustomPrice]=useState('');
  const [viewRec,setViewRec]=useState('');
  const [recId,setRecId]=useState('');
  const [careGiverName,setCareGiverName]=useState('');
  const [identification,setIdentification]=useState('');
  const [revokeComment,setRevokeComment]=useState('');
  const [drcomment,setDrcomment]=useState('');
  const [isOtherState,setIsOtherState]=useState(false);
  const [dimage, setDocImage] = useState('');
  const [DocumentFile, setDocFile] = useState([]);

  const config = {
    headers: {
      Authorization: `bearer ${SessionToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  };


  const validateForm=()=>{
    $(function() {
    $("#frm-patient-comments").validate({
      rules: {
        comment: {
          required: true,
          maxlength: 300,
        },
      
      
      },
    });
  });
  }
  let handleChangefile = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function() {
      //console.log(e.target.files[0]['name'])
      setDocImage(reader.result);
      setDocFile(e.target.files[0]);
      sessionStorage.setItem('docFile', reader.result);
      var DocType = e.target.files[0]['type'];
      var signStrFirstFive = DocType.substring(0,5);
      //setDocType(signStrFirstFive);
      }
      reader.readAsDataURL(file);
    // if((e.target.files[0]['type']).substring(0,5) != 'image'){
    //   setRequestResponse('License file must be Image.');
    //   setRequestResponseStatus('Failed');
    // }else{
    //   setRequestResponseStatus(0);
    //   setUploadImgHideCss('hideUploadImageCss');
    //   setUploadImgShowCss('showUploadImageCss');
    // }
  }
  let clearSignS = ()=>{
    setDocImage(0);
    setDocFile([]);
  }
  useEffect(() => {
    let data = {
      patient_id: id,
    };
    Axios.post("mPatientInfoById", data, config)
      .then((res) => {
        if (res.data.status == "unauthorised")  {
          Helpers.errorAlert(res.data.message, "error");
        } else {
          setIsOtherState(res.data.isOtherState);
          setUserInfo(res.data.user);
          setRecInfo(res.data.rec);
          setRecList(res.data.recList);
          if (userInfo.id > 0) {
            document.title =
              Label.PATIENT_INFO_TITLE +
              " | " +
              userInfo.fname +
              " " +
              userInfo.mname +
              " " +
              userInfo.lname;
          }

          validateForm();

        }
      })
      .catch((response) => {
        Helpers.errorAlert(response.data.error, "error");
      });
  }, []);
  useEffect(() => {
    document.querySelector("#breadcrumb").innerHTML = Label.PATIENT_INFO;
    const date = moment().format('YYYY-MM-DD');
  
  });


  useEffect(() => {

    document.querySelector("#breadcrumb").innerHTML = Label.MANUAL_REGISTER;

     $(function() {
    $("#frm-generate-caregiver-rec").validate({
      rules: {
        caregivername: {
          required: true,
        
        },
        identification: {
          required: true,
        
        },
      
      },
    });
  }); 
  
  }, []);

  const showErrors = (messages) => {
    let str = messages;
    if (Array.isArray(str)) {
      str = '';
      messages.map(function (data) {
        str += data + "\n";
      })
    }
    Helpers.errorAlert(str, 'error');
  }
  
  const generateREC=()=>{
    confirmAlert({
      title: 'Are you sure',
      message: 'You want to generate recommendation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
            setloadingRecButton(1);
            let formdata={
               patient_id:id,
              limit_exp: document.querySelector("#limit_exp").value,
               drcomment

            }
            Axios.post('mGenerateRec',formdata,config).then(res =>{
              setloadingRecButton(0);
              if(res.data.status=='unauthorised'){
                Helpers.Logout();
              }else if(res.data.status=='Failed'){
                showErrors(res.data.message);
              }else{
                setPlansInfo(res.data.plan);
                if (res.data.isPlanFlag == 1){
                  createRecforPaidUsers(res.data.orderId);
                } else {
                  window.$('#update-order').modal('show'); 
                }
                validateRecoForm();
               
            }
      
          });


          }
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }
  const validateRecoForm=()=>{
    $(function() {
      $("#frm-generate-rec").validate({
        rules: {
       
         customer_plans: {
          required: true,
         },

         
        },
      });
    });
  }
  const handlePlan=(e)=>{
      setPlanId(e.target.value);
    
  }

  const handleTransNumber=(e)=>{
    e.preventDefault();
    setTransNumber(e.target.value);

  }

  const handleLimitExp=(e)=>{
    e.preventDefault();
    setLimitExp(e.target.value);

  }
  
  const createRec=(e)=>{
    e.preventDefault();
    //console.log(feeStatus);
    const formdata={
       custom_price:customPrice,
       planId:planId,
       transNumber:tansNumber,
       limitExp:limitExp,
       patient_id:id,
       drnote:drcomment
    }
  
    Axios.post('mpstGenerateReco',formdata,config).then(res =>{
      setloadingRButton(0);
      if(res.data.status=='unauthorised'){
        Helpers.Logout();
      }else if(res.data.status=='Failed'){
        showErrors(res.data.message);
      }else{
        Helpers.errorAlert(res.data.message,'success')
        document.getElementById("frm-patient-comments").reset();
       // setCommentInfo(res.data.comment);
       window.$('#update-order').modal('hide');
       fetchPatientRec();
      
    }

  });
  }
  function createRecforPaidUsers(order_id) {
    const formdata= {
      order_id: order_id,
      user_id: id,
      comment: drcomment,
    }
  
    Axios.post('GenerateNewOrderRecommendation',formdata,config).then(res =>{
      setloadingRButton(0);
      if(res.data.status=='unauthorised'){
        Helpers.Logout();
      }else if(res.data.status=='Failed'){
        showErrors(res.data.message);
      }else{
        Helpers.errorAlert(res.data.message,'success')
        document.getElementById("frm-patient-comments").reset();
        // setCommentInfo(res.data.comment);
        window.$('#update-order').modal('hide');
        fetchPatientRec();
      }

  });
  }
  const handleCustomPrice=(e)=>{
    setCustomPrice(e.target.value);
  }
  const viewReco=(e,recom_id,user_id)=>{
    e.preventDefault();
    setloaderStatus(1);
    if(recom_id ** user_id){
      let formdata={
        recom_id:recom_id,
        user_id:user_id
      }
       

      const config = {
        headers: {
          Authorization: `bearer ${SessionToken}`,
       
        },
     
      };
    document.querySelector("#view-rec"+recom_id).innerHTML='<div class="spinner-border" style="height: 6px;width: 6px;margin-bottom: 4px;" role="status"><span className="sr-only"></span></div> View Rec';
    document.querySelector("#view-rec"+recom_id).disabled=true;
    Axios.post('mGetManualRecommendation',formdata,config).then((res) => {
      setloaderStatus(0);
      document.querySelector("#view-rec"+recom_id).innerHTML='View Rec';
      document.querySelector("#view-rec"+recom_id).disabled=false;
     
      setViewRec(res.data);
      window.$('#view-rec-modal').modal('show');
    })
    .catch((error) => {
      setloaderStatus(0);
      Helpers.errorAlert(error,'error');
    })

  }
    
  }
  const viewCareGiverReco=(e,recom_id,user_id)=>{
    e.preventDefault();
    if(recom_id ** user_id){
      let formdata={
        recId:recom_id,
        
      }
       

      const config = {
        headers: {
          Authorization: `bearer ${SessionToken}`,
       
        },
     
      };
    document.querySelector("#view-caregiverrec"+recom_id).innerHTML='<div class="spinner-border" style="height: 6px;width: 6px;margin-bottom: 4px;" role="status"><span className="sr-only"></span></div> View Rec';
    document.querySelector("#view-caregiverrec"+recom_id).disabled=true;
    Axios.post('mGetCargiverRecommendation',formdata,config).then((res) => {
      document.querySelector("#view-caregiverrec"+recom_id).innerHTML='View Rec';
      document.querySelector("#view-caregiverrec"+recom_id).disabled=false;
     
      setViewRec(res.data);
      window.$('#view-rec-modal').modal('show');
    })
    .catch((error) => {
    
      Helpers.errorAlert(error,'error');
    })

  }
    
  }
  const SendReco=(e,recom_id,user_id)=>{
    e.preventDefault();
    setloadingRButton(1);
    if(recom_id && user_id){

      confirmAlert({
        title: 'Are you sure',
        message: 'You want to send recommendation?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>{
            
              let formdata={
                recom_id:recom_id,
                user_id:user_id
              }
              document.querySelector("#send-rec"+recom_id).innerHTML='<div className="spinner-border" style="height: 8px;width: 7px;margin-bottom: 4px;" role="status"><span className="sr-only"></span></div>Send';
              document.querySelector("#send-rec"+recom_id).disabled=true;
              document.querySelector("#view-rec"+recom_id).disabled=true;
              Axios.post('mSendRecommendationMail',formdata,config).then(res =>{
                setloadingRButton(0);
                document.querySelector("#send-rec"+recom_id).innerHTML='Send';
                document.querySelector("#send-rec"+recom_id).disabled=false;
                if(res.data.status=='unauthorised'){
                  Helpers.Logout();
                }else if(res.data.status=='Failed'){
                  showErrors(res.data.message);
                }else{
                  Helpers.errorAlert(res.data.message,'success')
             
                
              }
            
            });
  
  
            }
          },
          {
            label: 'No',
            onClick: () => ''
          }
        ]
      });
    } 
  }
  const SendCaregiverReco=(e,recom_id)=>{
    e.preventDefault();
    setloadingRButton(1);
    if(recom_id){

      confirmAlert({
        title: 'Are you sure',
        message: 'You want to send recommendation?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>{
            
              let formdata={
                recId:recom_id,
              }
              document.querySelector("#send-rec"+recom_id).innerHTML='<div className="spinner-border" style="height: 8px;width: 7px;margin-bottom: 4px;" role="status"><span className="sr-only"></span></div>Send';
              document.querySelector("#send-rec"+recom_id).disabled=true;
              document.querySelector("#view-rec"+recom_id).disabled=true;
              Axios.post('mSendCargiverRecommendation',formdata,config).then(res =>{
                setloadingRButton(0);
                document.querySelector("#send-rec"+recom_id).innerHTML='Send';
                document.querySelector("#send-rec"+recom_id).disabled=false;
                if(res.data.status=='unauthorised'){
                  Helpers.Logout();
                }else if(res.data.status=='Failed'){
                  showErrors(res.data.message);
                }else{
                  Helpers.errorAlert(res.data.message,'success')
             
                
              }
            
            });
  
  
            }
          },
          {
            label: 'No',
            onClick: () => ''
          }
        ]
      });
    } 
  }

  const fetchPatientRec= () => {
      const formdata={
          patient_id:id
      };
      Axios.post('mFetchPatientRec',formdata,config).then(res =>{
        if(res.data.status=='unauthorised'){
          Helpers.Logout();
        }else if(res.data.status=='Failed'){
          showErrors(res.data.message);
        }else{
          setRecList(res.data.recList);
          setRecInfo(res.data.rec);

        }
    
    });

  }

  const addCareGiverRec=(e,recom_id,user_id)=>{
    setRecId(recom_id);
    window.$('#care-giver-modal').modal('show');

  }
  const handleCareGiverName = (e) => {
    setCareGiverName(e.target.value);
  }

   const handleIdentification=(e)=>{

    setIdentification(e.target.value);

  }
  const saveCaregiverRec=(e)=>{
    e.preventDefault();
    confirmAlert({
      title: 'Are you sure',
      message: 'You want to add caregiver?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
            document.querySelector('.btn-save-caregiver-info').innerHTML='<div className="spinner-border" style="height:10px;width:10px;margin-bottom: 5px;" role="status"><span className="sr-only"></span></div> Save';
            document.querySelector('.btn-save-caregiver-info').disabled=true;
            let formdata={
              careGiverName:careGiverName,
              identification:identification,
              recId:recId
            }
            Axios.post('mSaveCareGiverDetails',formdata,config).then(res =>{
              document.querySelector('.btn-save-caregiver-info').innerHTML='Save';
              document.querySelector('.btn-save-caregiver-info').disabled=false;
              if(res.data.status=='unauthorised'){
                Helpers.Logout();
              }else if(res.data.status=='Failed'){
                showErrors(res.data.message);
              }else{
                Helpers.errorAlert(res.data.message,'success');
                window.$('#care-giver-modal').modal('hide');
                fetchPatientRec();

           
              
            }
          
          });


          }
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
   
  }

  const RevokeReco=(e,recId,user_id)=>{
    setRecId(recId);
    window.$('#revoke-modal').modal('show');

  }


  const handleRevokeComment=(e)=>{
    setRevokeComment(e.target.value);
  }

  const saverevokeRec=(e)=>{
    e.preventDefault();

    confirmAlert({
      title: 'Are you sure',
      message: 'You want to revoke recommendation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
            document.querySelector('.btn-save-caregiver-info').innerHTML='<div className="spinner-border" style="height:7px;width:7px;margin-bottom: 5px;" role="status"><span className="sr-only"></span></div> Save';
            document.querySelector('.btn-save-caregiver-info').disabled=true;
            let formdata={
              recId: recId,
              revokeComment: revokeComment
            }
            Axios.post('mSaveRevokeDetails',formdata,config).then(res =>{
              document.querySelector('.btn-save-caregiver-info').innerHTML='Save';
              document.querySelector('.btn-save-caregiver-info').disabled=false;
              if(res.data.status=='unauthorised'){
                Helpers.Logout();
              }else if(res.data.status=='Failed'){
                showErrors(res.data.message);
              }else{
                Helpers.errorAlert(res.data.message,'success');
                window.$('#revoke-modal').modal('hide');
                fetchPatientRec();
              }
            });


          }
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });

  }

  const handleDrcomment=(e)=>{

    setDrcomment(e.target.value);

  }
  return (
    <>


<div className="modal " role="dialog" id="view-rec-modal">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
   
      <div className="modal-body" id="view-rec-body"   dangerouslySetInnerHTML={{__html:viewRec}}>
      
      </div>
      <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div className="modal " role="dialog" id="care-giver-modal">
<form id="frm-generate-caregiver-rec" method="post" onSubmit={saveCaregiverRec} >

  <div className="modal-dialog modal-mg" role="document">
    <div className="modal-content">
   
      <div className="modal-body" id="view-rec-body"  >
         
      <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Caregiver Name</label>
            <input type="text" className="form-control" name="caregivername" id="caregivername"  onChange={handleCareGiverName}/>
      </div>

      <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Identification ID</label>
            <input type="text" className="form-control" name="identification" id="identification"  onChange={handleIdentification}/>
      </div>
      </div>
      <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" className="btn btn-primary btn-save-caregiver-info" >Save</button>
      </div>
    </div>
  </div>
  </form>
</div>

<div className="modal " role="dialog" id="revoke-modal">
<form id="frm-revoke" method="post" onSubmit={saverevokeRec} >

  <div className="modal-dialog modal-mg" role="document">
    <div className="modal-content">
   
      <div className="modal-body" id="view-rec-body"  >
         
      <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Comment</label>
            <input type="text" className="form-control" name="comment" id="comment"  onChange={handleRevokeComment}/>
      </div>

      </div>
      <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" className="btn btn-primary btn-save-comment-info" >Revoke</button>
      </div>
    </div>
  </div>
  </form>
</div>
    <form id="frm-generate-rec" method="post" onSubmit={createRec} >
    <div className="modal fade" id="update-order"  role="dialog" aria-labelledby="update-order-title" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Update Information</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          
          <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Recipient Number/transaction number(Optional):</label>
            <input type="text" className="form-control" name="transNumber" id="transNumber"  onChange={handleTransNumber}/>
          </div>
          <div className="form-group">
          <ul className="list-group">
                    {(plansInfo &&
                plansInfo.length>0)? 
                plansInfo.map((plan) => (
                  <li  key={plan.id+""+plan.plan_name} className="list-group-item"><input type="radio" id={plan.id} onClick={handlePlan} name="customer_plans" value={plan.id}/> <label for={plan.id}>{!!plan.plan_value&&plan.plan_value} {plan.plan_name} <p dangerouslySetInnerHTML={{__html:plan.plan_discription}}></p></label></li>
                  )) :''
            }
          </ul>
          </div>
          
            <div className="form-group">
              <label htmlFor="message-text" className="col-form-label">Custom Price:</label>
              <input type="number" className="form-control" id="custom_price" name="custom_price"  onClick={handleCustomPrice}/>
            </div>
        
            <div className="form-group">
              <label htmlFor="message-text" className="col-form-label">Dr.Note:</label>
              <textarea className="form-control" id="comment" name="comment"  onClick={handleDrcomment}></textarea>
            </div>
        
      
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    </form>
    <div className="content-header">
        {userInfo.id > 0 && (
          <h1 className="page-title">
            Patient Information
            {/* {userInfo[0].fname +
              " " +
              (userInfo[0].mname ? userInfo[0].mname : "") +
              " " +
              userInfo[0].lname} */}
          </h1>
        )}
    </div>
    <div className="manual_rec">
        {userInfo.id > 0 ? (
          <div className="d-lg-flex align-items-start">
            <div className="manual_rec_lf">
              
              <div className="rec_title pb-3">
                  {isOtherState == true?
                  <>
                  <div>
                    <div className="recommendationFile">
                        <label>Select Recommendation file</label>
                        <form className="form mt-2">
                        <div className="file-upload-wrapper" data-text={DocumentFile.name == null?'Select image':DocumentFile.name}>
                            <input  type="file" className="file-upload-field" name="image" onChange={handleChangefile} />
                        </div>
                        </form>
                    </div>
                    {dimage == ''?'':
                    <div className="pdfbox rec_title pt-3">
                        <iframe src={dimage} width='100%' height='250px' className="mt-2" frameBorder="0" />
                        <button className="btn btn-primary" onClick={clearSignS}>X</button>
                    </div>
                    }
                    <div>
                        <button className="btn btn-primary">Send Recommendation</button>
                    </div>
                </div>
                  </>
                  :
                  

                  <>
                  <div className="form-group">
                      <label>Valid For</label>
                      <input type="text" className="form-control" name="limitmonth" value={'12 Month'} />
                  </div>
                  <div className="form-group">
                      <label>Limit Exemption</label>
                      <input type="text" className="form-control" name="limit_exp" id="limit_exp"  onChange={handleLimitExp} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message-text" className="col-form-label">Dr.Note:</label>
                      <textarea className="form-control" id="comment" name="comment" style={{padding: '10px', height: 'auto'}} rows="2" onChange={handleDrcomment}></textarea>
                    </div>
                  <div className="form-group">
                    <center>
                      { loadingRecButton == 1 ?  <button className="btn submit-btn theme-button" disabled> <FaSpinner/> Please wait...</button>: <button className="btn submit-btn theme-button" onClick={generateREC}>Approve</button>
                      }
                      </center>
                  </div>
                  </>
                    }



              </div>
              
              <div className="rec_title pt-3 disabled">
                
                {userInfo.state == 6?<>
                  { recList.length>0 ?
                recList.map((rec) => (
                <div className={"media mb-4" + (rec.rec_status==2 ? ' revoke' : '')}>
                  <div className="media-body">
                    { rec.care_giver_name?<h5 className="mt-0"><b>Caregiver</b>: {rec.care_giver_name}</h5>:<h5 className="mt-0"><b>Patient</b>: {rec.full_name}</h5>}
                    <small className="l_no">Rec Id : {rec.recom_id}</small><br/>
                    <small className="l_no">valid From <Moment format="MM/DD/YYYY">{rec.recom_issuedate}</Moment> to <Moment format="MM/DD/YYYY ">{rec.recom_expdate}</Moment></small><br></br>
                    { rec.care_giver_name?<>
                      <Link to="#" className="medi_a" onClick={(e)=>viewCareGiverReco(e,rec.id,rec.user_id)} id={'view-caregiverrec'+rec.id} >View Rec</Link>
                      <Link to="#" className="medi_a" onClick={(e)=>SendCaregiverReco(e,rec.id)}  id={'send-rec'+rec.id} >Send Rec</Link>
                    </>
                    :''}
                    { rec.save_dr_notes== null?'': rec.save_dr_notes== ''?'':<p className="doc_comment">Doctor Comment: {rec.save_dr_notes}</p>}                                                                  
                    {rec.recom_type != 'manual'?'':
                      <>
                      { moment().format('YYYY-MM-DD')>= moment(rec.recom_expdate).format('YYYY-MM-DD') || rec.rec_status!='1'  || rec.recId!=null  ?'':
                        <Link to="#" className="medi_a" onClick={(e)=>addCareGiverRec(e,rec.id,rec.user_id)}>Add Caregiver</Link>
                      }
                      </>
                    }
                      <>
                      {rec.care_giver_name == null?<>
                      <Link to="#" className="medi_a" onClick={(e)=>viewReco(e,rec.id,rec.user_id)}  id={'view-rec'+rec.id}>View Rec</Link>
                      {rec.rec_status!='1' || moment().format('YYYY-MM-DD')>= moment(rec.recom_expdate).format('YYYY-MM-DD') ?'':
                      <Link to="#"  onClick={(e)=>SendReco(e,rec.id,rec.user_id)}  id={'send-rec'+rec.id} className="medi_a">Send Rec</Link>
                      }
                      {rec.rec_status!='1' || moment().format('YYYY-MM-DD')>= moment(rec.recom_expdate).format('YYYY-MM-DD') ?'':
                      <Link to="#"  onClick={(e)=>RevokeReco(e,rec.id,rec.user_id)}  id={'send-rec'+rec.id} className="medi_a">Revoke Rec</Link>
                      }
                      </>:''}
                      
                      </>
                    
                    {
                      rec.care_giver_name!=null && rec.identification && rec.recId != null?
                      <div className="media mt-3">
                          <div className="media-body">
                            <h5 className="mt-0"><b>Patient</b>: {rec.full_name}</h5>
                            <small className="l_no">License No : {rec.recom_id}</small><br></br>
                            <Link to="#" className="medi_a" onClick={(e)=>viewReco(e,rec.id,rec.user_id)}  id={'view-rec'+rec.id}>View Rec</Link>
                            
                            {rec.rec_status!='1' || moment().format('YYYY-MM-DD')>= moment(rec.recom_expdate).format('YYYY-MM-DD') ?'':
                                <Link to="#"  onClick={(e)=>SendReco(e,rec.id,rec.user_id)}  id={'send-rec'+rec.id} className="medi_a">Send Rec</Link>
                            }
                            {rec.rec_status!='1' || moment().format('YYYY-MM-DD')>= moment(rec.recom_expdate).format('YYYY-MM-DD') ?'':
                            <Link to="#"  onClick={(e)=>RevokeReco(e,rec.id,rec.user_id)}  id={'send-rec'+rec.id} className="medi_a">Revoke Rec</Link>
                            }
                          </div>
                      </div>:''

                    }

                  </div>
                </div>
              ))
                :'No Rec found'
                }
                </>
                :''}
                
                
                    
              </div>
            </div>
            <div className="manual_rec_right">
              <div className="row">
                <div className="col-xl-12">
                  <div className="manual_rec_block">
                    <div className="d-flex align-items-center">
                      <small className="h4">
                        {userInfo.name}
                      </small>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6">
                        <label>
                          DOB: {userInfo.dob && <>
                            <Moment format="MM/DD/YYYY">{userInfo.dob}</Moment> (Age: {moment().diff(userInfo.dob, 'years', false) })
                          </>}
                        </label>
                        <label>Gender: {userInfo.gender}</label>
                        <label>Email: <a href={'mailto:' + userInfo.email} target="_blank">{userInfo.email}</a></label>
                        <label>Phone: <a href={'tel:' + userInfo.mobile} target="_blank">{userInfo.mobile}</a></label>
                        <label>ID and Signature:</label>
                        <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '10px'}}>
                          <a href={userInfo.id_proof} target="_blank"><img src={userInfo.id_proof} width='100' /></a>
                          <a href={userInfo.signature} target="_blank"><img src={userInfo.signature} width='100' /></a>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <label>Address: {userInfo.address}</label>
                        <label>City: {userInfo.address}</label>
                        <label>State: {userInfo.state_name}</label>
                        <label>Zipcode: {userInfo.zipcode}</label>
                        <div>
                          <label>Diseases:</label>
                          <ul>
                            {userInfo && userInfo.diseases.map(d => <li>{d.title}</li>)}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-5 mt-4 mt-xl-0">
                  <div className="manual_rec_block">
                    <div className="d-flex align-items-center">
                      <small className="h4">Latest Rec</small>
                      {   
                      recInfo.length>0? '':''
                    }
                    </div>
                    <div className="row mt-4">
                    {   
                    recInfo.length>0? 
                    recInfo.map((rec) => (
                      <div className="col-md-12">
                        <label>Rec Id:{rec.recom_id}</label>
                        <label>Rec By: Dr.{rec.fname} .{rec.mname} .{rec.lname}</label>
                        <label>Valid Date:{rec.recom_issuedate?<Moment format="MM/DD/YYYY">{rec.recom_issuedate}</Moment>:''} to {rec.recom_expdate?<Moment format="MM/DD/YYYY ">{rec.recom_expdate}</Moment>:''}</label>
                        <label>Dr.Note</label>
                        <p>{rec.save_dr_notes}</p>
                      </div>

                      )) :'No Data Found'
                
                    }
                    </div>
                  </div>
                </div> */}

                <Comments user_id={ id } show_errors={showErrors}></Comments>

              </div>
            </div>
          </div>


        ) : (
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
    </div>
    <div className={`lds-ellipsis ${loaderStatus == 0?'hideDivLoader':'showDivLoader'}`} >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
    </div>
    </>
  );
}
export default Patientprofile;
