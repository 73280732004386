import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, Link } from "react-router-dom";
function Topbar() {
    const navigate = useNavigate();
   
    
  return (
    <>
	<div className="page-header">
        <div className="page-header-fix">
          <div className="mob-header">
            <div className="row align-items-center">
              <div className="col-8 col-md-6 pr-0 pr-md-3">
                <nav className="navbar navbar-default navbar-expand-md">
                  <div className="container-fluid">
                    <div className="toggle_bar_mob">
                      <a href="#" id="sidebar-toggle-button">
                        <img src="../../assets/dashboard/img/toggle-icon.png" alt="" />
                      </a>
                    </div>
                    <div className="navbar_left">
                      <ul className="nav navbar-nav">
                        <li className="collapsed-sidebar-toggle-inv d-none d-md-block">
                          <a
                            href="#"
                            id="collapsed-sidebar-toggle-button"
                          >
                            <img src="../../assets/dashboard/img/toggle-icon.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" onClick={() => navigate(-1)} >
                            <FaArrowLeft/>
                          </a>
                        </li>
                        {/* <li>
                          <a href="#" id="toggle-fullscreen">
                            <img src="../assets/dashboard/img/expand-icon.png" alt="" />
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-4 col-md-6">
               {/*userInfo.length!=0 &&
                <div className="d-flex align-items-center justify-content-end">
                  <div className="mob-logo">
                    <a href="#" className="logo-box">
                      <img src="../assets/dashboard/img/logo-2.png" alt="" />
                    </a>
                  </div>
                  <div className="secondary-sidebar-profile d-flex align-items-center">
                    <div className="profile_box">
                    {userInfo.profile_pic? <img src={userInfo.profile_pic}  alt={userInfo.name}/>: <img alt={userInfo.name} src="../assets/website/img/user.jpg" /> }
                      
                      {userInfo.is_online==3? <span className="status online"></span>:<span className="status offline"></span> }
                    </div>
                    <div className="profile_txt">
                      <h3>{userInfo.name}</h3>
                      <h4>
                        {/* Status {userInfo.is_online==3? <span className="state on">Online</span>: <span className="state off">Offline</span> } */}
                       {/* Status
                        <div className="toggle">
                            <input type="checkbox" className="check" />
                            <b className="b switch"></b>
                            <b className="b track"></b>
                        </div>
                      </h4>
                    </div>
                  </div>
                  
                </div>
                */}
              </div>
            </div>
          </div>
          {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-style-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">
                Update Profile
              </li>
            </ol>
          </nav> */}
        </div>
      </div>








    </>
);
}
export default Topbar;