import React, { useState, useEffect, useContext } from 'react';
import { Container, Grid, Card, Text, Group, Skeleton, Alert, CopyButton, TextInput, Stack, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { TbDiscount2 } from "react-icons/tb";
import { FaUsers, FaUserCheck } from "react-icons/fa";
import Axios from "../../axios2";
import AppContext from "../../AppContext";

function ReferralStats() {
    const user = useContext(AppContext).context?.user;
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sendingInvite, setSendingInvite] = useState(false);

    const site_id = process.env.REACT_APP_SITE_ID;

    const form = useForm({
        initialValues: {
            email: '',
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    useEffect(() => {
        Axios.get('/referrals')
            .then(response => {
                setStats(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.response?.data?.message || 'Failed to load referral statistics');
                setLoading(false);
            });
    }, []);

    if (error) {
        return (
            <Container size="lg" py="xl">
                <Alert color="red" title="Error">
                    {error}
                </Alert>
            </Container>
        );
    }

    return (
        <Container size="lg" py="xl">
            <Text size="xl" weight={700} mb="lg">Referral Statistics</Text>
            <Grid>
                <Grid.Col span={{md: 4, base: 12}}>
                    <Card shadow="sm" p="lg" radius="md" withBorder>
                        <Skeleton visible={loading} height={loading ? 140 : 'auto'}>
                            <Group justify='space-between'>
                                <div>
                                    <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
                                        Total Referrals
                                    </Text>
                                    <Text size="xl" weight={700}>
                                        {stats?.all_referral_count || 0}
                                    </Text>
                                </div>
                                <FaUsers size={32} color="#228be6" />
                            </Group>
                            <Text c="dimmed" size="xs">* All sign-ups through your referrals.</Text>
                        </Skeleton>
                    </Card>
                </Grid.Col>
                
                <Grid.Col span={{md: 4, base: 12}}>
                    <Card shadow="sm" p="lg" radius="md" withBorder>
                        <Skeleton visible={loading} height={loading ? 140 : 'auto'}>
                            <Group justify='space-between'>
                                <div>
                                    <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
                                        Paid Referrals
                                    </Text>
                                    <Text size="xl" weight={700}>
                                        {stats?.paid_referral_count || 0}
                                    </Text>
                                </div>
                                <FaUserCheck size={32} color="#40c057" />
                            </Group>
                            <Text c="dimmed" size="xs">* Referrals that made a purchase.</Text>
                        </Skeleton>
                    </Card>
                </Grid.Col>
                
                <Grid.Col span={{md: 4, base: 12}}>
                    <Card shadow="sm" p="lg" radius="md" withBorder>
                        <Skeleton visible={loading} height={loading ? 140 : 'auto'}>
                            <Group justify='space-between'>
                                <div>
                                    <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
                                        Referral Discount
                                    </Text>
                                    <Text size="xl" weight={700}>
                                        ${stats?.referral_discount || 0}
                                    </Text>
                                </div>
                                <TbDiscount2 size={32} color="#fd7e14" />
                            </Group>
                            <Text c="dimmed" size="xs">* Discount valid for renewals only.</Text>
                        </Skeleton>
                    </Card>
                </Grid.Col>
            </Grid>
            
            <Card shadow="sm" p="lg" radius="md" withBorder mt="xl">
                <Stack>
                    <Text weight={500} size="lg">Your Referral Link</Text>
                    <Text size="sm" color="dimmed">
                        Share this link with others to earn referral benefits
                    </Text>
                    <Group grow>
                        <TextInput
                            readOnly
                            value={`https://app2.${site_id == 1 ?  'onlinemedicalcard.com' : 'myesadoctor.com'}/register?ref=${stats?.referral_code}`}
                        />
                        <CopyButton value={`https://app2.${site_id == 1 ?  'onlinemedicalcard.com' : 'myesadoctor.com'}/register?ref=${stats?.referral_code}`}>
                            {({ copied, copy }) => (
                                <button
                                    onClick={() => {
                                        copy();
                                        notifications.show({
                                            title: 'Success',
                                            message: 'Referral link copied to clipboard!',
                                            color: 'green'
                                        });
                                    }}
                                    style={{
                                        padding: '10px 15px',
                                        backgroundColor: copied ? '#40c057' : '#228be6',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {copied ? 'Copied!' : 'Copy Link'}
                                </button>
                            )}
                        </CopyButton>
                    </Group>
                </Stack>
            </Card>

            <Card shadow="sm" p="lg" radius="md" withBorder mt="xl">
                <Stack>
                    <Text weight={500} size="lg">Invite Friends via Email</Text>
                    <Text size="sm" color="dimmed">
                        Send an invitation to your friends and earn rewards when they join
                    </Text>
                    <form onSubmit={form.onSubmit(async (values) => {
                        setSendingInvite(true);
                        try {
                            await Axios.post('/referrals/email', 
                                { email: values.email }
                            );
                            notifications.show({
                                title: 'Success',
                                message: 'Invitation sent successfully!',
                                color: 'green'
                            });
                            form.reset();
                        } catch (error) {
                            notifications.show({
                                title: 'Error',
                                message: error.response?.data?.message || 'Failed to send invitation',
                                color: 'red'
                            });
                        }
                        setSendingInvite(false);
                    })}>
                        <Group grow>
                            <TextInput
                                placeholder="friend@email.com"
                                {...form.getInputProps('email')}
                            />
                            <Button 
                                type="submit"
                                loading={sendingInvite}
                                style={{
                                    backgroundColor: '#228be6',
                                    color: 'white'
                                }}
                            >
                                Send Invitation
                            </Button>
                        </Group>
                    </form>
                </Stack>
            </Card>
            </Container>
    );
}

export default ReferralStats;

