import $ from "jquery";
import "jquery-validation";
import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Label from "../../config/DoctorLabel";
import Axios from "../../axios";
import Moment from "react-moment";
import "moment-timezone";
import Helpers from "../../components/doctor/Helper";
import { FaSpinner } from "react-icons/fa";
const Changepassword = () => {


    const SessionToken=window.sessionStorage.getItem('token');

    const config = {
      headers: { Authorization: `bearer ${SessionToken}`,"Access-Control-Allow-Origin": "*"}
    };

    const [loadingRButton, setloadingRButton] = useState(0);

    

    useEffect(() => {

        document.querySelector("#breadcrumb").innerHTML = Label.CHANGE_PASSWORD_HEADING;
    
        $("#frm-change-password").validate({
            rules: {
                oldpassword: {
                    required: true,
                },
                cpassword : {
                    minlength : 7
                },
                cpassword_confirm : {
                    minlength : 7,
                    equalTo : "#cpassword",
                   
                }
            },
          });
    
          
    
      }, []);
    
    
      const changePassword = (e) => {
        e.preventDefault();

        let data={
            oldpassword:document.querySelector('#oldpassword').value,
            cpassword:document.querySelector('#cpassword').value,
            cpassword_confirm:document.querySelector('#cpassword_confirm').value,

        };
        setloadingRButton(1)

        Axios.post('mAuthchangepassword',data,config).then(res =>{
            setloadingRButton(0);
            if(res.data.status=='unauthorised'){
              Helpers.Logout();
            }else if(res.data.status=='Failed'){
              Helpers.errorAlert(res.data.message,'error');
            }else{
              Helpers.errorAlert(res.data.message,'success')
            

              restForm();
           }
        }).catch(response =>{
            console.log(response.message);
            Helpers.errorAlert(response.message,'error')
        });
      
      };

     const restForm = () => { 
        document.getElementById("frm-change-password").reset();
      }
    return (
        <form onSubmit={changePassword} method="post" id="frm-change-password">

        <div>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="content-header">
                        <h2 className="page-title">Change Password</h2>
                    </div>
                    <div className="patient_table doctor_data mt-4 shadow">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group mb-4">
                                    <label>{Label.OLD_PASSWORD}</label>
                                    <input type="password" className="form-control" required  name="oldpassword" id="oldpassword"/>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group mb-4">
                                    <label>{Label.NEW_PASSWORD}</label>
                                    <input type="password" className="form-control" required  name="cpassword"  id="cpassword"/>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group mb-4">
                                    <label>{Label.CONFIRM_PASSWORD}</label>
                                    <input type="password" className="form-control" required  name="cpassword_confirm"  id="cpassword_confirm" />
                                </div>
                            </div>
                            <div className="col-md-8 col-xl-4">
                                <div className="form-group button-group d-flex">
                                   

                                    {loadingRButton === 1 ?     
                                     <button className="btn submit-btn theme-button" disabled> <FaSpinner /> Loading</button>
                                    :
                                    <button className="btn submit-btn theme-button" type="submit">Update</button>}

                                    <button className="btn close-btn theme-button" type='reset' onClick={restForm}>{Label.BTN_CLEAR}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
    );
};
export default Changepassword;