import { useEffect, useState } from "react";
import Moment from "react-moment";
import Axios from '../../axios';
import Axios2 from '../../axios2';
import Helpers from "./Helper";
import { FaSpinner } from "react-icons/fa";

const Comments = (props) => {

  const user_id = props.user_id;
  const [loading, setLoading] = useState(1);
  const SessionToken = window.sessionStorage.getItem("token");
  const [commentInfo, setCommentInfo] = useState([]);

  const config = {
    headers: {
      Authorization: `bearer ${SessionToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  useEffect(() => {
    getComments();
  }, []);


  const getComments = () => {
    Axios2.get('/patients/' + user_id + '/comments')
      .then((res) => {
        setCommentInfo(res.data.data);
      }).finally(() => setLoading(0))
  }

  const createPatientComments = (e) => {
    e.preventDefault();
    setLoading(1);
    const formdata = new FormData(e.target)
    formdata.append('user_id', user_id);

    Axios.post('mAddPatientComment', formdata, config).then(res => {
      setLoading(0);
      if (res.data.status == 'unauthorised') {
        Helpers.Logout();
      } else if (res.data.status == 'Failed') {
        Helpers.errorAlert(res.data.message, 'error');
      } else {
        Helpers.errorAlert(res.data.message, 'success')
        document.getElementById("frm-patient-comments").reset();
        setCommentInfo(res.data.comment);
      }

    });
  }

  return <>
    <div className="col-12 mt-4 manual_rec_right" style={{padding: '10px'}}>
      <form id="frm-patient-comments" method="post" onSubmit={createPatientComments} >
        <textarea placeholder="Add a Comment" name="comment" style={{border: '1px solid'}}></textarea>
        <div className="form-group button-group d-flex">
          {loading == 1 ?
            <button className="btn submit-btn theme-button" disabled> <FaSpinner /> Loading</button>
            :
            <button className="btn submit-btn theme-button" type="submit">Comment</button>}

        </div>
      </form>
      <div id="accordion">
        {


          commentInfo.length > 0 ?
            commentInfo.map((comment) => (
              <div className="card">
                <div className="card-header" id={"heading" + comment.id}>
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapse" + comment.id} aria-expanded="false" aria-controls={"collapse" + comment.id}>
                      Comment Date : {comment.created_at ? <Moment format="MM/DD/YYYY HH:ss">{comment.created_at}</Moment> : ''}
                    </button>
                  </h5>
                </div>
                <div id={"collapse" + comment.id} className="collapse" aria-labelledby={"heading" + comment.id} data-parent="#accordion">
                  <div className="card-body">
                    {comment.comment}
                    <hr></hr>
                    <b>Comment By: Dr. {comment.comment_by}</b>
                  </div>
                </div>
              </div>
            )) : ''
        }
      </div>
    </div>
  </>
}

export default Comments;