import React, { useState, useEffect }from "react";
import { useNavigate, Link, useParams,useLocation } from "react-router-dom";
import Axios from '../../axios';
import Helpers from "../../components/doctor/Helper";
import { FaTimes } from "react-icons/fa";


const Sidebar=()=>{

  const [userInfo, setUserinfo] = useState([]);
  const [activeMenu,setActiveMenu]=useState(false);
  const SessionToken=window.sessionStorage.getItem('token');
  const config = {
    headers: { Authorization: `bearer ${SessionToken}` }
  };

  const location = useLocation();
  let path =location.pathname.replace('/','');

  useEffect(() => {
   
    setActiveMenu(path);
    Axios.post('dGetDoctorprofile','',config).then(res =>{
          if(res.data.status=='unauthorised'){
            Helpers.Logout();
          }else if(res.data.status=='Failed'){
            Helpers.errorAlert(res.data.message,'error');
          }else{
            setUserinfo(res.data.datainfo);
           }
			}).catch(response =>{
        Helpers.errorAlert(response.data.error,'error')
			});
  },[]);
  const logoutDoctor=()=>{
     window.sessionStorage.clear();
     window.location.href='/';
  }
  const setActive=(activM)=>{
    setActiveMenu(activM)
  }
  return (
    <>
      <div className="secondary-sidebar">
        <div id="sidebar-toggle-button-close">
          <i className="icon-close"></i>
        </div>
        <div className="secondary-sidebar-bar">
          <Link to="/dashboad" className="logo-box">
            <img src="../../assets/dashboard/img/logo-2.png" alt="" />
          </Link>
        </div>
        <div className="mobile-sidebar">
          <a href="#" id="close-menu"><FaTimes/></a>
          <div className="secondary-sidebar-menu menu-top scrollbar verticle-scroll">
            <ul className="accordion-menu">
              <li   className={activeMenu=='dashboard' ? "active-page": ""}  onClick={() => setActive('dashboard')}>
                <Link to="/">
                  <span>
                    <img
                      src="../../assets/dashboard/img/dashboard-icon.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Orders</label>
                </Link>
              </li>
             
              {/* <li  className={activeMenu=='manual-register-patient' || activeMenu=='edit-profile' ? "active-page": ""} onClick={() => setActive('manual-register-patient')}>
                <Link to="manual-register-patient">
                  <span>
                    <img
                      src="../assets/dashboard/img/MAnnual-Register-patient.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Manual Register Patient</label>
                </Link>
              </li>
              <li className={activeMenu=='auto-register-patient' ? "active-page": ""} onClick={() => setActive('auto-register-patient')}>
                <Link to="auto-register-patient">
                  <span>
                    <img
                      src="../assets/dashboard/img/Auto register-patient.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Auto Register Patient</label>
                </Link>
              </li> */}
              {/* <li className={activeMenu=='my-order-list' ? "active-page": ""}  onClick={() => setActive('my-order-list')}>
                <Link to="/my-order-list">
                  <span>
                  <img
                      src="../assets/dashboard/img/Oder-list_1.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Recommendation List</label>
                </Link>
              </li> */}
              {/* <li className={activeMenu=='add-manual-patient' ? "active-page": ""}  onClick={() => setActive('add-manual-patient')}>
              <Link to="add-manual-patient">
                  <span>
                    <img
                      src="../assets/dashboard/img/Add-patient.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Add Patient</label>
              </Link>
              </li> */}
              {/* <li>
              <Link to="patient-recommendation">
                  <span>
                    <img
                      src="../assets/dashboard/img/recommendation-icon.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Patient Recommendation</label>
                  </Link>
              </li> 
              <li>
                <Link to="/edit-profile">
                  <span>
                    <img
                      src="../assets/dashboard/img/profile-icon.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Edit Profile</label>
                </Link>
              </li>
              */}
              <li className={activeMenu=='my-profile' ? "active-page": ""}  onClick={() => setActive('my-profile')}>
                <Link to="/my-profile">
                  <span>
                    <img
                      src="../assets/dashboard/img/My-profile.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>My Profile</label>
                </Link>
              </li>
           
              <li className={activeMenu=='change-password' ? "active-page": ""}  onClick={() => setActive('change-password')}>
                <Link to="change-password">
                  <span>
                    <img
                      src="../assets/dashboard/img/Change-Password.png"
                      alt=""
                    />
                  </span>{" "}
                  <label>Change Password</label>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sidebar_bottom">
            <div className="secondary-sidebar-profile">
              <Link to="/viewProfile"><img src={userInfo.profile_pic == undefined ? `../assets/website/img/user.jpg` : userInfo.profile_pic} id="left-profile-img" alt="viewProfile" /></Link>
              <div id="left-profile-name">
                <h6>{userInfo.name}</h6>
                <Link className={activeMenu == 'contactus' ? "active-page" : ""} to="contactus" onClick={() => setActive('contactus')} ><img src="../../assets/dashboard/img/contact-icon.png" alt="Contact us" /> <span>Contact us</span></Link>
                <Link to="#" onClick={logoutDoctor}><img src="../../assets/dashboard/img/logout-icon.png" alt="Logout" /><span>Logout</span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Sidebar;
