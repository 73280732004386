import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import Helpers from "../../components/doctor/Helper";
import Filter from "./UsersFilter";
import { DatesProvider, DateTimePicker } from "@mantine/dates";
import {
  Table,
  Pagination,
  Badge,
  Button,
  Tabs,
  Modal,
  Textarea,
  TextInput,
  Grid,
  GridCol,
  InputLabel,
  Select,
  Input,
} from "@mantine/core";
import AppContext from "../../AppContext";
import axios2 from "../../axios2";
import { TiThSmall } from "react-icons/ti";
import { FaCalendar, FaCheck, FaCheckDouble, FaPhone, FaTimes, FaUser } from "react-icons/fa";
import { TbProgress } from "react-icons/tb";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import CalendarView from "./CalendarView";
import moment from "moment/moment";
import SlotDataDrawer from "./SlotDataDrawer";
import CalendarDatepicker from "./CalendarDatepicker";
import { FaMagnifyingGlass, FaMessage } from "react-icons/fa6";
import { showNotification } from "@mantine/notifications";
import { Phone } from "../../components/Phone";

const LeadList = () => {
  const context = useContext(AppContext).context;
  
  const [filters, setFilters] = useState({});
  const [tab, setTab] = useState("all");
  const [useFilter, setUseFilter] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [data, setData] = useState([]);
  const { state } = useLocation();
  const [rerenderKey,setRerenderKey] = useState(0)
  const [defaultDate, setDefaultDate] = useState();
  const [doneModalOpened, doneModal] = useDisclosure(false);

  const [counts, setCounts] = useState({});

  const userData = useContext(AppContext).context?.user;

  const [overviewOpened, overviewModal] =useDisclosure(false);
  const [leadOpened, leadModal] =useDisclosure(false);
  
  const [nextAppointment, setNextAppointment] = useState({});

  const [selectedDate, setSelectedDate] = useState(moment());

  const [drawerDate, setDrawerDate] = useState(false);

  const [doctors, setDoctors] = useState([]);

  const [detailModalOpened, detailModal] = useDisclosure(false);
  const [selectedAppointment, setSelectedAppointment] = useState();


const SELECT_VALUES = [
  { id: 1, label: "ADHD Treatment", value: "adhdh_treatment" },
  { id: 2, label: "Anxiety Disorder", value: "anxiety_disrorder" },
  { id: 3, label: "Emotional support animal letter", value: "ESA" },
  { id: 4, label: "Erectile dysfunction", value: "erectile_dysfunction" },
  { id: 5, label: "General medicine", value: "general_medicine" },
  { id: 6, label: "Hormone replacement therapy", value: "hormone_therapy" },
  { id: 7, label: "Skin tag removal", value: "skin_tag_remvoal" },
  { id: 8, label: "Suboxone Treatment", value: "suboxone_treatment" },
  { id: 9, label: "Weight loss", value: "weight_loss" },
  { id: 10, label: "MMJ Card", value: "mmj_card" },
  { id: 11, label: "Other services", value: "other" },
];

  const leadForm = useForm({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      appointment_for: "",
      patient_note: "",
      due_date: defaultDate,
      dr_id: '',
    },
    validate: {
      name: (value) => value.length >= 3 ? null : "Please enter 3 chars at least",
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      mobile: (value) => value.length > 3 ? null : "Please enter 3 chars at least",
      due_date: (value) => value ? null : "Please enter appointment date",
    },
  });

  const doneForm = useForm({
    initialValues: {
      note: "",
      title: "",
      due_date: "",
      dr_id: '',
    },
    validate: {
      due_date: (value) => !value && "Please select an appointment date",
      title: (value) => !value && "Please enter 2 chars at least",
    },
  });

  const [selectedLead, setSelectedLead] = useState(null);

  useEffect(() => {
    fetchData();
  }, [context, filters]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateCounts();
    }, 10000);

    axios2.get('/steadycare-leads/doctors').then(resp => setDoctors(resp.data.data));

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if(tab == 'calendar') return;
    setFilters({ ...filters, status: tab == "all" ? "" : tab });
  }, [tab]);

  const fetchData = (page, _filters) => {
    if (!context.user) return;
    page = page ? page : 1;
    setData([]);
    if (_filters) setFilters(_filters);
    const filters_query = new URLSearchParams(
      _filters ? _filters : filters
    ).toString();
    axios2
      .get("steadycare-leads?page=" + page + "&" + filters_query)
      .then((res) => {
        setData(res.data);
      })
      .catch((response) => {
        Helpers.errorAlert(response.data.message, "error");
      });
      updateCounts();
  };

  const updateCounts = () => {
    axios2.get('/steadycare-leads/counts').then(res => {
      console.log('counts', res.data);
      setCounts(res.data);
    })
  }

  const onClickFilterCallback = (filters) => {
    setUseFilter(true);
    fetchData(1, filters);
  };

  const schedule = (lead) => {
    setSelectedLead(lead);
    axios2.get('/steadycare-leads/due_date').then(data => {
      doneForm.setValues({due_date: moment(data.data.due_date).toDate()});
    })
    doneModal.open();
  };

  const saveDone = (data) => {
    setIsLoader(true);
    console.log('selectedLead', selectedLead);
    axios2
      .post("steadycare-leads/" + selectedLead.id + "/appointments", data)
      .then((res) => {
        fetchData();
        doneModal.close();
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  async function submitAddLead(data) {
    setIsLoader(true);
    try {
      const res = await axios2.post("/steadycare-leads/", data);
      if (res) {
        setNextAppointment(res.data.data);
        leadModal.close();
        overviewModal.open();
        setRerenderKey(prev => prev+1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  }

  const openLeadForm = useCallback((day, time) => {
    if(day && time) leadForm.setValues({due_date: moment(day+' '+time, 'YYYY-MM-DD HH:mm').toDate()});
    leadModal.open();
  }, []);

  const openDrawer = useCallback((day, time) => {
    setDrawerDate({day, time});
  }, []);

  const openDetailModal = useCallback((appointment) => {
    setSelectedAppointment(appointment);
    detailModal.open();
  }, []);

  const formatPhone = (phone) => {
    if(!phone) return '';
    return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2) $3 - $4");
  };

  return (
    <>
      <div className="content-header">
        <div
          class="patient-title"
          style={{ display: "flex", alignItems: "center", gap: "1rem" }}
        >
          <h1 className="page-title">All Appointments </h1>
          <Button onClick={() => {
            leadForm.reset();
            axios2.get('/steadycare-leads/due_date').then(data => {
              leadForm.setValues({due_date: moment(data.data.due_date).toDate()});
            })
            leadModal.open();
          }}>Add Appointment</Button>
        </div>
      </div>

      <div className="patient_table">
        <div className="row filter_block">
          <div className="col-12">
            <div className="search_box">
              <img
                src="../../assets/dashboard/img/search-icon.png"
                alt="Search Patient"
              />
              <Filter
                onClickFilterCallback={onClickFilterCallback}
                state={state}
                leads={1}
              ></Filter>
            </div>
          </div>
        </div>
        <div>
          <Tabs defaultValue="all" value={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.List>
              <Tabs.Tab value="all" leftSection={<TiThSmall size="0.8rem" />}>
                All
              </Tabs.Tab>
              <Tabs.Tab value="new" leftSection={<FaUser size="0.8rem" />}>
                New <Badge color={counts.new ? 'red' : 'green'}>{counts.new ?? 0}</Badge>
              </Tabs.Tab>
              <Tabs.Tab
                value="scheduled"
                leftSection={<FaCheckDouble size="0.8rem" />}
              >
                Scheduled
              </Tabs.Tab>
              <Tabs.Tab
                value="waiting"
                leftSection={<TbProgress size="0.8rem" />}
              >
                Waiting
              </Tabs.Tab>
              <Tabs.Tab
                value="canceled"
                leftSection={<FaTimes size="0.8rem" />}
              >
                Canceled
              </Tabs.Tab>
              <Tabs.Tab
                value="closed"
                leftSection={<FaCheckDouble size="0.8rem" />}
              >
                Closed
              </Tabs.Tab>
              <Tabs.Tab
                value="followup"
                leftSection={<FaMagnifyingGlass size="0.8rem" />}
              >
                Follow-up <Badge color={counts.followup ? 'red' : 'green'}>{counts.followup ?? 0}</Badge>
              </Tabs.Tab>
              <Tabs.Tab
                value="calendar"
                leftSection={<FaCalendar size="0.8rem" />}
              >
                Calendar
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </div>
        {tab != "calendar" && (
          <>
            {" "}
            <div className="table_wrap" style={{ marginTop: "1em" }}>
              {data.data ? (
                <Table striped withTableBorder withColumnBorders>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th scope="col">No.</Table.Th>
                      <Table.Th scope="col">Craeted At</Table.Th>
                      <Table.Th scope="col">Name</Table.Th>
                      <Table.Th scope="col">Email</Table.Th>
                      <Table.Th scope="col">Mobile</Table.Th>
                      <Table.Th scope="col">Status</Table.Th>
                      <Table.Th scope="col">Action</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data.data.length > 0 ? (
                      data.data.map((lead) => (
                        <Table.Tr key={lead.id}>
                          <Table.Td style={{ padding: "1.8rem" }}>
                            {lead.id}
                          </Table.Td>
                          <Table.Td>
                            <Moment format="MM/DD/YYYY">
                              {lead.created_at}
                            </Moment>
                          </Table.Td>
                          <Table.Td style={{ padding: "1.8rem" }}>
                            {lead.name}
                          </Table.Td>
                          <Table.Td>{lead.email}</Table.Td>
                          <Table.Td><Phone mobile={lead.mobile} email={lead.email} name={lead.name} /></Table.Td>
                          <Table.Td>
                            <Badge>{lead.status}</Badge>
                          </Table.Td>
                          <Table.Td>
                            {lead.next_appointment ? (
                              <>
                                <Badge
                                  variant="outline"
                                  color="green"
                                  size="xs"
                                >
                                  Scheduled for{" "}
                                  <Moment format="MM/DD/YYYY HH:mm">
                                    {lead.next_appointment.due_date}
                                  </Moment> 
                                </Badge>
                              </>
                            ) : (
                              <Button
                                variant="light"
                                color="yellow"
                                size="xs"
                                onClick={() => schedule(lead)}
                              >
                                Unscheduled
                              </Button>
                            )}
                            &nbsp;
                            <Link to={"/leads/" + lead.id} target="_blank">
                              <Button
                                variant="light"
                                m={5}
                                color="blue"
                                size="xs"
                              >
                                Details
                              </Button>
                            </Link>
                          </Table.Td>
                        </Table.Tr>
                      ))
                    ) : (
                      <Table.Tr>
                        <Table.Td colSpan="7">
                          <center>No Data found</center>
                        </Table.Td>
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                </Table>
              ) : (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
            <br />
            {data.meta && (
              <Pagination
                defaultValue={data.meta.current_page}
                onChange={fetchData}
                total={data.meta.last_page}
              />
            )}
          </>
        )}
        {tab == "calendar" && (
          <div style={{ padding: "2rem 0" }}>
            <CalendarDatepicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <CalendarView onOpenDetailModal={openDetailModal} onOpenLeadForm={openLeadForm} onOpenDrawer={openDrawer} key={rerenderKey} selectedDate={selectedDate}/>
          </div>
        )}
      </div>

      <SlotDataDrawer date={drawerDate}/>

      <Modal
        opened={doneModalOpened}
        size="lg"
        onClose={doneModal.close}
        title="Schedule Next Appointment"
      >
        <form onSubmit={doneForm.onSubmit(saveDone)}>
          <DatesProvider settings={{ timezone: process.env.REACT_APP_TIMEZONE }}>
            <DateTimePicker
              dropdownType="modal"
              {...doneForm.getInputProps("due_date")}
              label="Appointment Date"
              minDate={new Date()} // Prevents selecting past dates
              valueFormat="MM/DD/YYYY HH:mm" // USA date format
              required // Makes it mandatory
            />
          </DatesProvider>
          <TextInput label="Appointment Reason" {...doneForm.getInputProps("title")} />
          <Textarea label="Appointment Note" {...doneForm.getInputProps("note")}></Textarea>
          <Select
          label='Doctor'
          data={doctors.map(d => ({id: d.id, label: d.name, value: d.id.toString()}))}
          {...doneForm.getInputProps("dr_id")}
           />
          <Button type="submit" loading={isLoader}>
            Save Appointment
          </Button> &nbsp;
          <Button loading={isLoader} color="gray" onClick={() => doneModal.close()}>
            Not Sure
          </Button>
        </form>
      </Modal>
      <Modal
        opened={leadOpened}
        size="lg"
        onClose={leadModal.close}
        title="Add Appointment"
      >
        <form onSubmit={leadForm.onSubmit(submitAddLead)}>
        <Grid>
          <span style={{ display: "flex", width: "100%" }}>
            <GridCol span={6}>
              <InputLabel htmlFor="name">Full name</InputLabel>
              <Input
                name="name"
                id="name"
                placeholder="Full name"
                {...leadForm.getInputProps("name")}
              />
            </GridCol>
            <GridCol span={6}>
              <InputLabel htmlFor="email">Email address</InputLabel>
              <Input
                {...leadForm.getInputProps("email")}
                name="email"
                type="email"
                id="email"
                placeholder="email address"
              />
            </GridCol>
          </span>
          <span style={{ display: "flex", width: "100%" }}>
            <GridCol span={6}>
              <InputLabel htmlFor="mobile">Phone number</InputLabel>
              <Input
                {...leadForm.getInputProps("mobile")}
                name="mobile"
                id="mobile"
                inputMode="numeric"
                placeholder="Phone number"
              />
            </GridCol>
            <GridCol span={6}>
              <InputLabel htmlFor="appointment_for">Appointment for</InputLabel>
              <Select
                style={{ zIndex: 999999999 }}
                id="appointment_for"
                data={SELECT_VALUES}
                {...leadForm.getInputProps("appointment_for")}
              />
            </GridCol>
          </span>
          <span style={{ display: "flex", width: "100%" }}>
            {/* <GridCol span={6}>
              <InputLabel htmlFor="mobile">Due time</InputLabel>
              <TimeInput
                onChange={(e) => handleInputChange("due_time", e.target.value)}
                name="due_time"
                id="due_time"
                ref={ref}
                value={patientData.due_time}
                rightSection={pickerControl}
              />
            </GridCol> */}
            <GridCol span={12}>
              {/* <DatePickerInput
              value={new Date(patientData.due_day)}
                name="due_day"
                placeholder="Pick date"
                onChange={(e)=>handleInputChange('due_day',e)}
              /> */}
              <Select
              label='Doctor'
              data={doctors.map(d => ({id: d.id, label: d.name, value: d.id.toString()}))}
              {...leadForm.getInputProps("dr_id")}
              />
              <DatesProvider settings={{ timezone: process.env.REACT_APP_TIMEZONE }}>
                <DateTimePicker
                  dropdownType="modal"
                  {...leadForm.getInputProps("due_date")}
                  label="Appointment Date"
                  minDate={new Date()} // Prevents selecting past dates
                  valueFormat="MM/DD/YYYY HH:mm" // USA date format
                  required // Makes it mandatory
                />
              </DatesProvider>
            </GridCol>
          </span>
          <GridCol span={12}>
            <InputLabel>Patient note</InputLabel>
            <Textarea
              {...leadForm.getInputProps("patient_note")}
              placeholder="Patient note"
              name="patient_note"
              id="patient_note"
            />
          </GridCol>
          <Button loading={isLoader} fullWidth type="submit">
            Add patient
          </Button>
        </Grid>
        </form>
      </Modal>
      <Modal
        opened={overviewOpened}
        onClose={overviewModal.close}
        title={nextAppointment?.next_appointment?.title}
      >
        <Grid mt={12}>
          <span style={{ display: "flex", width: "100%" }}>
            <GridCol span={6}>
              <p style={{ fontWeight: "700" }}>Patient name:</p>
              <p>{nextAppointment?.name}</p>
            </GridCol>
            <GridCol span={6}>
              <p style={{ fontWeight: "700" }}>Patient email:</p>
              <p>{nextAppointment?.email}</p>
            </GridCol>
          </span>
          <span style={{ display: "flex", width: "100%" }}>
            <GridCol span={6}>
              <p style={{ fontWeight: "700" }}>Patient mobile:</p>
              <p>{nextAppointment?.mobile}</p>
            </GridCol>
            <GridCol span={6}>
              <p style={{ fontWeight: "700" }}>Appointment for:</p>
              <p>{nextAppointment?.appointment_for}</p>
            </GridCol>
          </span>
          <span style={{ display: "flex", width: "100%" }}>
            <GridCol span={6}>
              <p style={{ fontWeight: "700" }}>Appointment date:</p>
              <Badge variant="light" color="green">
                {moment(nextAppointment?.due_date).format("MM-DD-YYYY HH:mm")}
              </Badge>
            </GridCol>
            <GridCol span={6}>
              <p style={{ fontWeight: "700" }}>Appointment status:</p>
              <Badge
                variant="light"
                color={
                  nextAppointment?.next_appointment?.status == "planned"
                    ? "yellow"
                    : nextAppointment?.next_appointment?.status == "done"
                    ? "green"
                    : "red"
                }
              >
                {nextAppointment?.next_appointment?.status}
              </Badge>
            </GridCol>
          </span>
          <span style={{ display: "flex", width: "100%" }}>
            <GridCol span={6}>
              <p style={{ fontWeight: "700" }}>Doctor:</p>
              {nextAppointment?.next_appointment?.doctor_name}
            </GridCol>
          </span>
        </Grid>
      </Modal>
      <Modal
        opened={detailModalOpened}
        onClose={detailModal.close}
        title="Appointment Info"
      >
        <div><strong>ID:</strong> {selectedAppointment?.id}</div>
        <div><strong>Name:</strong> {selectedAppointment?.name}</div>
        <div><strong>Doctor:</strong> {selectedAppointment?.doctor_name}</div>
        <div><strong>Email:</strong> {selectedAppointment?.email}</div>
        <div><strong>Mobile:</strong> <Phone mobile={selectedAppointment?.mobile} email={selectedAppointment?.email} name={selectedAppointment?.name}/>
        </div>
        <div><strong>Appointment Date:</strong> {moment(selectedAppointment?.due_date).format('MM/DD/YYYY HH:mm')}</div>
        <div style={{margin: '5px'}}><Select value={selectedAppointment?.status} data={['done', 'canceled', 'planned', 'no_show', 'rescheduled']} onChange={(_value, option) => {
          if(!_value) return;
          const lead_id = selectedAppointment.lead_id;
          axios2.post('/appointments/' + selectedAppointment.id, {status: _value});
          selectedAppointment.status = _value;
          setSelectedAppointment({...selectedAppointment});

          setSelectedDate(moment(selectedDate));

          showNotification({
            title: "Saved.",
            color: "green",
            icon: <FaCheck />,
          });

          if(_value == 'done' || _value == 'no_show' || _value == 'canceled'){
            axios2.get('/steadycare-leads/' + lead_id).then(data => {
              setSelectedLead(data.data.data);
              detailModal.close();
              doneModal.open();
            })
          }

        }}></Select></div>

        <div style={{textAlign: 'center'}}><Button variant="outline"><Link to={`/leads/${selectedAppointment?.lead_id}`} target="_blank">
        Open Patient's File
        </Link></Button></div>

      </Modal>
    </>
  );
};
export default LeadList;
