import React,{useState } from 'react';
import axios from "../../axios";
import Parser from 'html-react-parser';
import label from "../../config/DoctorLabel";
import Loginfooter from './Loginfooter';
import { Link } from 'react-router-dom';
import { FaCheck, FaSpinner } from 'react-icons/fa';
function Forgotpasword(){
    const [loadingRButton, setloadingRButton] = useState(0);
    const [emailResponse, setemailResponse] = useState(0);
    const [emailResponseStatus, setemailResponseStatus] = useState(0);
    let [account,setAccount] = useState({
        email: '',
        emailError:'',
         
    });
    let handleChange = (e) => {
		let name = e.target.name; 
        
		if(name === 'rememberme'){
			const checked = e.target.checked;
            //alert(checked)
			if(checked === true){
				let name = e.target.name;
				let value = checked;
				account[name] = value;
				setAccount(account);
			}else{
				let name = e.target.name;
				let value = '';
				account[name] = value;
				setAccount(account);
			}
            //validate();
		}else{
			let name = e.target.name;
			let value = e.target.value;
			account[name] = value;
			setAccount(account);
		}
		validate();
	}
    const validate = (e) => {
		let emailError = "";
		const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (!account.email || reg.test(account.email) === false) {
		    emailError = "Email Field is Invalid ";
		}
		
		if (emailError ) {
			let email = account.email;
			
		
			setAccount({emailError, email });
			
		  return false;
		}

		let email = account.email;
		
		setAccount({emailError, email });	
		return true;
	  }
    const hideEmailverifyMessage = ()=>{
        setemailResponseStatus('null');
        setemailResponse('');
    }
    let SubmitPressed = (e) => {
        e.preventDefault();
        if (validate()) {
            setloadingRButton(1)
            axios.post('dForgetPassword', { email: account.email })
                .then(res => {
                    console.log(res.data);
                    setloadingRButton(0)
                if(res.data.status === 'Success'){
                    setemailResponseStatus(res.data.status);
                    setemailResponse(res.data.message);
                    //alert(res.data.message);
                    //navigate('/home');
                }else{
                    setemailResponseStatus(res.data.status);
                    setemailResponse(res.data.message);
                }
            })
        }
    }
    return(
        <main id="dashboard"> 
       
        <div className="page-container">
            <div className="login d-lg-flex align-items-lg-center">
                <div className="login-content">
                    <div className="login-box">
					    
					    <div className="login-logo">
                            <div className="content"><Link to="/">{Parser(label.logo)}</Link></div>
						</div>
                        {/* <div className='responseError'>
                            {responseError === 0 ? '':<span className="text-danger">{responseError}</span>}
                        </div> */}
                        
                        <div className="login-body">
                            <div className="login-header">
                                <div className="content">{Parser(label.forgotpassword_heading_description)}</div>
                                    <div className={`emailResponse ${emailResponseStatus} == 'Success'? forgotPasswordAlert : ''}`} >
                                        {emailResponseStatus === 'Success' ?
                                        <div className="alert success">
                                            <span className="closebtn success" onClick={hideEmailverifyMessage}>×</span>
                                            <FaCheck /> 
                                            <b>{emailResponse}</b>
                                        </div>
                                        :emailResponseStatus === 'Failed'?
                                        <div className="alert danger">
                                            <span className="closebtn danger" onClick={hideEmailverifyMessage}>×</span>
                                            <FaCheck/>
                                            <b>{emailResponse}</b>
                                        </div>
                                        :
                                        ''
                                        }
                                    </div>
                                </div>
                            <div className="row">
							  <div className="col-xl-12">
							    <form onSubmit={SubmitPressed}>
									<div className="form-group">
										<img src="img/mail.png" alt=""/>
										<input type="email"  className="form-control" name='email' onChange={handleChange} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
									</div>
                                    <span className="text-danger">{account.emailError}</span>
                                    {loadingRButton === 1 ?     
                                    <button className="btn btn-primary" disabled>
                                        <FaSpinner /> Loading
                                    </button>
                                    :
                                    <button type="submit" className="btn btn-primary">{label.forgotpassword_button}</button>}
								</form>
							  </div>
							</div>
                            <br/>
                            <div className="container">
                                <div className="row">
                                    <p className="m-t-sm forgot"><Link to="/login">{label.register_login_button}</Link></p>
                                </div>
                            </div>
                            
                        </div>
                         <Loginfooter></Loginfooter>
                    </div>
                </div>
            </div>
        </div>
        
        </main>
    )
}
export default Forgotpasword;