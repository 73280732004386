import {
  Button,
  Checkbox,
  FileInput,
  Group,
  Modal,
  NativeSelect,
  Radio,
  Stepper,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import axios from "../axios2";
import { useEffect, useState } from "react";
import styles from "../styles/OrderSteps.module.css";
import {
  FaCheck,
  FaCheckCircle,
  FaExclamationCircle,
  FaTicketAlt,
  FaVideo,
} from "react-icons/fa";
import { setTime } from "@progress/kendo-react-dateinputs/dist/npm/utils";
import moment from "moment";

function OrderSteps({
  order,
  is_doctor,
  updateOrder,
  size = "md",
  orientation = "vertical",
}) {
  const [doneModalOpened, doneModal] = useDisclosure(false);
  const [currentStep, setCurrentStep] = useState();
  const [viewFileModalOpenned, viewFileModal] = useDisclosure(false);
  const [file, setFile] = useState("");
  const [currentOrder, setCurrentOrder] = useState(order);
  const [medicalConditions, setMedicalConditions] = useState(
    Array(17).fill("")
  );
  const site_id = process.env.REACT_APP_SITE_ID;

  console.log(medicalConditions);

  const [loading, setLoading] = useState(false);

  const [time, setTime] = useState("");

  const [scheduleModalOpened, scheduleModal] = useDisclosure(false);
  const [periods, setPeriods] = useState([]);

  const doneform = useForm({
    initialValues: {
      note: "",
      exemption: order.plan.exemption_limit,
      valid_for: order.plan.valid_for,
    },
    validate: {},
  });

  const showDoneModal = (step) => {
    doneform.reset();
    setMedicalConditions(Array(17).fill(""));
    if (step.type == "patientfile" && step.done_by) {
      setFile(step.file);
      viewFileModal.open();
      return;
    }
    // if(step.done_at){
    //   showNotification({
    //     title: 'Error',
    //     message: 'This step is already done.',
    //     color: 'red',
    //     icon: <FaExclamationCircle />
    //   });
    //   return;
    // }
    if (step.type == "patient") {
      showNotification({
        title: "Error",
        message: "This step must be done by the patient, please wait.",
        color: "red",
        icon: <FaExclamationCircle />,
      });
      return;
    }
    setCurrentStep(step);
    doneModal.open();
  };

  const reserve = () => {
    setLoading(true);
    axios
      .post("orders/" + order.id + "/times/reserve/" + time)
      .then((res) => {
        showNotification({
          title: "Done",
          message: res.data.message,
          color: "green",
          icon: <FaCheck />,
        });
        scheduleModal.close();
        return;
      })
      .finally(() => setLoading(false));
  };

  const openScheduleModal = () => {
    setLoading(true);
    scheduleModal.open();
    axios
      .get("orders/" + order.id + "/times")
      .then((res) => {
        setPeriods(res.data.data);
      })
      .finally(() => setLoading(false));
  };

  const sendDoneForm = (values) => {
    setLoading(true);

    const formData = new FormData();
    Object.keys(values).map((key) => formData.append(key, values[key]));
    formData.append("step_id", currentStep.id);
    if (!values.file) formData.delete("file");

    formData.append("medical_conditions", medicalConditions);

    axios
      .post(
        "orders/" +
          currentOrder.id +
          (currentStep.type == "patientfile"
            ? "/patient-done-step"
            : "/done-step"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        if (updateOrder) updateOrder(resp.data.data);
        setCurrentOrder(resp.data.data);
        doneModal.close();
        showNotification({
          title: "Done",
          message: currentStep.doctor_title + " is done.",
          color: "green",
          icon: <FaTicketAlt />,
        });
        return;
      })
      .finally(() => setLoading(false));
  };

  const shouldAllowSelectStep = (step) => {
    const active = currentOrder.current_step.step_number;
    if (is_doctor && step.type != "patientfile") {
      return true;
    }
    if (is_doctor && step.type == "patientfile" && step.file) {
      return true;
    }
    if (!is_doctor && step.type == "patientfile") {
      return true;
    }
    return false;
  };

  const handleClick = (index) => {
    const step = currentOrder.steps[index];
    if (!shouldAllowSelectStep(step)) return;
    showDoneModal(step);
  };

  const updateMedicalConditions = (i, v) => {
    if (currentStep.type == "iowa") {
      v = v - 1;
      if (v == -1) return;
      const v1 = i == 0 ? "P" : "S";
      for (let k in medicalConditions) {
        if (medicalConditions[k] == v1) medicalConditions[k] = "";
      }
      medicalConditions[v] = v1;
      setMedicalConditions([...medicalConditions]);
    } else {
      let md = medicalConditions.filter((v) => v !== "");
      if (v) {
        md.push(i);
      } else {
        md.splice(md.indexOf(i), 1);
      }
      console.log(md);
      setMedicalConditions(md);
    }
  };

  const getCurrentStepDescription = () => {
    if (!currentOrder.current_step) return "";
    let text = is_doctor
      ? currentOrder.current_step.dr_decription
      : currentOrder.current_step.patient_decription;
    if (!text) return "";
    let dr_mobile = currentOrder?.doctor?.calling_phone;
    dr_mobile = dr_mobile
      ? dr_mobile.substr(1, 3) +
        "-" +
        dr_mobile.substr(4, 3) +
        "-" +
        dr_mobile.substr(7)
      : "";
    console.log("dr_mobile", dr_mobile);
    text = text.replaceAll(":dr_mobile:", dr_mobile);
    text = text.replaceAll(":dr_name:", currentOrder?.doctor?.name);
    text = text.replaceAll(":dr_lname:", currentOrder?.doctor?.last_name);
    text = text.replaceAll(":patient_name:", currentOrder?.user.name);
    return text;
  };

  return (
    <>
      <div
        className={[
          styles.steps,
          orientation == "horizontal" ? styles.horizontal : "",
          size == "small" ? styles.small : "",
        ].join(" ")}
      >
        {currentOrder.steps.length !== 0 &&
          currentOrder.steps.map(
            (item, index) => {
              return (
                <div
                  className={[
                    styles.step,
                    item.done_by ? styles.done : "",
                    item.step_number == currentOrder.current_step.step_number
                      ? styles.active
                      : "",
                  ].join(" ")}
                >
                  <div
                    onClick={() => handleClick(index)}
                    className={
                      shouldAllowSelectStep(item) ? styles.clickable : ""
                    }
                  >
                    <div className={styles.icon}>
                      {item.done_by ? (
                        <svg
                          viewBox="0 0 10 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 4.586L1.707 2.293A1 1 0 1 0 .293 3.707l3 3a.997.997 0 0 0 1.414 0l5-5A1 1 0 1 0 8.293.293L4 4.586z"
                            fill="currentColor"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        item.step_number
                      )}
                    </div>
                    <div className={styles.title}>
                      {is_doctor ? item.doctor_title : item.patient_title}
                    </div>
                    <div className={styles.description}>
                      {is_doctor ? item.doctor_subtitle : item.patient_subtitle}
                    </div>
                  </div>
                  {item.step_number ==
                    currentOrder.current_step.step_number && (
                    <div className={styles.longDesc}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: getCurrentStepDescription(),
                        }}
                      ></p>
                      {order.current_step.type == "videocall" &&
                        order.meeting?.id && (
                          <div style={{ textAlign: "center" }}>
                            <strong>
                              Video call scheduled for today at{" "}
                              {moment(order.meeting?.start_at).format("HH:mm")}
                            </strong>
                          </div>
                        )}
                      {order.current_step.type == "videocall" && !is_doctor && (
                        <div style={{ textAlign: "center" }}>
                          <Button
                            color="green"
                            onClick={() => openScheduleModal()}
                          >
                            <FaVideo /> &nbsp;Schedule Video Call
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            }
            // <Stepper.Step  allowStepClick={shouldAllowSelectStep(item)} allowStepSelect={shouldAllowSelectStep(item)} color={item.type == 'patientfile' && !item.file && is_doctor ? 'gray' : 'green'} label={is_doctor ? item.doctor_title : item.patient_title} description={is_doctor ? item.doctor_subtitle : item.patient_subtitle}>
            // </Stepper.Step>
          )}
      </div>
      {/* <Stepper size={size} color="green" allowNextStepsSelect={false} onStepClick={(step) => handleClick(step)} active={active} breakpoint="sm" orientation={orientation} style={{ marginTop: '1rem' }}>
      {currentOrder.steps.length !== 0 &&
        currentOrder.steps.map((item, index) =>
          <Stepper.Step icon={item.type == 'patientfile' && item.done_by ? <FaCheckCircle color="#40c057" size="3rem" /> : ''} allowStepClick={shouldAllowSelectStep(item)} allowStepSelect={shouldAllowSelectStep(item)} color={item.type == 'patientfile' && !item.file && is_doctor ? 'gray' : 'green'} label={is_doctor ? item.doctor_title : item.patient_title} description={is_doctor ? item.doctor_subtitle : item.patient_subtitle}>
          </Stepper.Step>
        )
      }
    </Stepper> */}

      <Modal
        opened={doneModalOpened}
        size="lg"
        onClose={doneModal.close}
        title={currentStep?.doctor_title + "?"}
      >
        <form onSubmit={doneform.onSubmit(sendDoneForm)}>
          {currentStep && currentStep.type == "note" && (
            <Textarea
              placeholder=""
              label="Note"
              autosize
              style={{ resize: "vertical" }}
              minRows={5}
              {...doneform.getInputProps("note")}
            />
          )}
          {currentStep && currentStep.validfor == true && (
            <TextInput
              placeholder=""
              label="Valid for (months)"
              {...doneform.getInputProps("valid_for")}
            />
          )}
          {currentStep && currentStep.exemption == true && (
            <TextInput
              placeholder=""
              label="Exemption"
              {...doneform.getInputProps("exemption")}
            />
          )}
          {currentStep &&
            (currentStep.type == "file" ||
              currentStep.type == "montana" ||
              currentStep.type == "montana2" ||
              currentStep.type == "patientfile") && (
              <FileInput
                placeholder="Pick file"
                label="Please select a file here"
                {...doneform.getInputProps("file")}
                withAsterisk
              />
            )}

          {currentStep &&
                  currentStep.type == "montana2" && (
                  <FileInput
                    placeholder="Pick file 2"
                    label="Please select a file here"
                    {...doneform.getInputProps("file2")}
                    withAsterisk
                  />
                )}

          {currentStep && currentStep.type == "iowa" && (
            <>
              <NativeSelect
                label="Primary Condition"
                placeholder="---"
                data={[
                  "---",
                  "Cancer with severe or chronic pain",
                  "Cancer with nausea or severe vomiting",
                  "Cancer with cachexia or severe wasting",
                  "Multiple sclerosis with severe and persistent muscle spasms",
                  "Seizures, including those characteristic of epilepsy",
                  "AIDS or HIV as defined in Iowa Code, section 141A.1",
                  "Crohn’s disease",
                  "Ulcerative colitis",
                  "Amyotrophic lateral sclerosis",
                  "*Any terminal illness with a probable life expectancy of under one year and severe or chronic pain (please see bottom of page 2)",
                  "*Any terminal illness with a probable life expectancy of under one year and nausea or severe vomiting (please see bottom of page 2)",
                  "*Any terminal illness with a probable life expectancy of under one year and cachexia or severe wasting (please see bottom of page 2)",
                  "Parkinson’s disease",
                  "Chronic pain",
                  "Severe, intractable autism with self-injurious or aggressive behaviors",
                  "Corticobasal Degeneration",
                  "Post-Traumatic Stress Disorder (PTSD)",
                ]}
                onChange={(event) =>
                  updateMedicalConditions(0, event.currentTarget.selectedIndex)
                }
              />
              <NativeSelect
                label="Secondary Condition"
                data={[
                  "---",
                  "Cancer with severe or chronic pain",
                  "Cancer with nausea or severe vomiting",
                  "Cancer with cachexia or severe wasting",
                  "Multiple sclerosis with severe and persistent muscle spasms",
                  "Seizures, including those characteristic of epilepsy",
                  "AIDS or HIV as defined in Iowa Code, section 141A.1",
                  "Crohn’s disease",
                  "Ulcerative colitis",
                  "Amyotrophic lateral sclerosis",
                  "*Any terminal illness with a probable life expectancy of under one year and severe or chronic pain (please see bottom of page 2)",
                  "*Any terminal illness with a probable life expectancy of under one year and nausea or severe vomiting (please see bottom of page 2)",
                  "*Any terminal illness with a probable life expectancy of under one year and cachexia or severe wasting (please see bottom of page 2)",
                  "Parkinson’s disease",
                  "Chronic pain",
                  "Severe, intractable autism with self-injurious or aggressive behaviors",
                  "Corticobasal Degeneration",
                  "Post-Traumatic Stress Disorder (PTSD)",
                ]}
                onChange={(event) =>
                  updateMedicalConditions(1, event.currentTarget.selectedIndex)
                }
              />
            </>
          )}
          {currentStep && currentStep.type == "montana" && (
            <>
              <Checkbox
                label="Cancer, glaucoma, or positive status for human immunodeficiency virus, or acquired immune deficiency syndrome when the condition or disease results in symptoms that seriously and adversely affect the patient’s health status"
                onChange={(event) =>
                  updateMedicalConditions(0, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Cachexia or wasting syndrome"
                onChange={(event) =>
                  updateMedicalConditions(1, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Severe chronic pain that is persistent pain of severe intensity that significantly interferes with daily activities as documented by the patient’s treating physician"
                onChange={(event) =>
                  updateMedicalConditions(2, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Intractable nausea or vomiting"
                onChange={(event) =>
                  updateMedicalConditions(3, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Epilepsy or an intractable seizure disorder"
                onChange={(event) =>
                  updateMedicalConditions(4, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Multiple sclerosis"
                onChange={(event) =>
                  updateMedicalConditions(5, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Crohn’s disease"
                onChange={(event) =>
                  updateMedicalConditions(6, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Painful peripheral neuropathy"
                onChange={(event) =>
                  updateMedicalConditions(7, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="A central nervous system disorder resulting in chronic, painful spasticity or muscle spasms"
                onChange={(event) =>
                  updateMedicalConditions(8, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Admittance into hospice care"
                onChange={(event) =>
                  updateMedicalConditions(9, event.currentTarget.checked)
                }
              />
              <Checkbox
                mb="xl"
                label="Post-traumatic stress disorder (PTSD)"
                onChange={(event) =>
                  updateMedicalConditions(10, event.currentTarget.checked)
                }
              />
            </>
          )}
          {currentStep && currentStep.type == "arizona" && (
            <>
              <Checkbox
                label="Acquired immune deficiency syndrome (AIDS)"
                onChange={(event) =>
                  updateMedicalConditions(0, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Amyotrophic lateral sclerosis (ALS)"
                onChange={(event) =>
                  updateMedicalConditions(1, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Crohn's disease"
                onChange={(event) =>
                  updateMedicalConditions(2, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Human immunodeficiency virus (HIV)"
                onChange={(event) =>
                  updateMedicalConditions(3, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Agitation of Alzheimer's disease"
                onChange={(event) =>
                  updateMedicalConditions(4, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Cancer"
                onChange={(event) =>
                  updateMedicalConditions(5, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Glaucoma"
                onChange={(event) =>
                  updateMedicalConditions(6, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Hepatitis C"
                onChange={(event) =>
                  updateMedicalConditions(7, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Post-Traumatic Stress Disorder (PTSD)"
                onChange={(event) =>
                  updateMedicalConditions(8, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Cachexia or wasting syndrome"
                onChange={(event) =>
                  updateMedicalConditions(9, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Severe and chronic pain"
                onChange={(event) =>
                  updateMedicalConditions(10, event.currentTarget.checked)
                }
              />
              <Checkbox
                label="Severe nausea"
                onChange={(event) =>
                  updateMedicalConditions(11, event.currentTarget.checked)
                }
              />

              <Checkbox
                label="Seizures, including epilepsy characteristic"
                onChange={(event) =>
                  updateMedicalConditions(12, event.currentTarget.checked)
                }
              />
              <Checkbox
                mb="xl"
                label="Severe or persistent muscle spasms, including those characteristic of multiplesclerosis"
                onChange={(event) =>
                  updateMedicalConditions(13, event.currentTarget.checked)
                }
              />

              <TextInput
                  placeholder=""
                  label="IF ANY CONDITION ABOVE IS CHECKED, INDICATE THE UNDERLYING CHRONIC OR DEBILITATING DISEASE OR MEDICAL CONDITION:"
                  {...doneform.getInputProps("note")}
              />

            </>
          )}
          <Group>
            <Button type="submit" color="green" loading={loading}>
              Submit
            </Button>
            <Button
              type="button"
              onClick={() => doneModal.close()}
              color="gray"
              loading={loading}
            >
              Cancel
            </Button>
          </Group>
        </form>
      </Modal>

      <Modal
        opened={viewFileModalOpenned}
        size="xl"
        onClose={viewFileModal.close}
        title="View File"
      >
        <embed
          src={
            site_id == 2 && file
              ? file.replace("admin.onlinemedicalcard", "api2.myesadoctor")
              : file
          }
          frameborder="0"
          width="100%"
          height="400px"
        ></embed>
      </Modal>

      <Modal
        opened={scheduleModalOpened}
        size="xl"
        onClose={scheduleModal.close}
        title="Schedule a video meeting"
      >
        <Radio.Group
          value={time}
          onChange={setTime}
          name="schedulemeeting"
          label="Please select a meeting time:"
          description="Doctor will call you at the selected time"
          withAsterisk
        >
          <Group m="lg" justify="left">
            {periods.map((p) => (
              <Radio
                style={{ width: "130px" }}
                value={p.label.substr(0, p.label.indexOf("-"))}
                label={p.label}
                disabled={!p.is_available}
              />
            ))}
          </Group>
        </Radio.Group>
        <Group justify="center">
          <Button
            type="submit"
            color="green"
            onClick={() => reserve()}
            loading={loading}
          >
            Reserve
          </Button>
          <Button
            type="button"
            onClick={() => scheduleModal.close()}
            color="gray"
            loading={loading}
          >
            Cancel
          </Button>
        </Group>
      </Modal>
    </>
  );
}

export default OrderSteps;
