import {
  Avatar,
  Button,
  Card,
  Loader,
  ScrollArea,
  Textarea,
} from "@mantine/core";
import { useEffect, useState, useRef, useContext } from "react";
import { MdSend } from "react-icons/md";
import { useDebouncedCallback } from "use-debounce";
import "./chatbox.css";
import axios from "../../axios2";
import { TiTimes } from "react-icons/ti";
import AppContext from "../../AppContext";
export default function ChatBox({
  leadMobile: mobile,
  open,
  close,
  name,
  email,
}) {
  console.log(mobile);
  const [message, setMessage] = useState("");
  const [conversations, setConversations] = useState([]);
  const [isConvLoading, setIsConvLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const { context } = useContext(AppContext);
  const can_see_numbers = context?.user?.can_see_numbers;
  const debounced = useDebouncedCallback((func) => {
    func();
  }, 5000);
  const scrollAreaRef = useRef(null);
  useEffect(() => {
    async function getUserConversations(mobile) {
      setIsConvLoading(true);
      try {
        const conversations = await axios.get(`/chats?mobile=${mobile}`);
        // setConversations(conversations);
        setConversations(conversations.data.data)
        
      } catch (error) {
        setError(error.response.data.message);
      } finally {
        setIsConvLoading(false);
      }
    }
    getUserConversations(mobile);
  }, [open, mobile]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollAreaRef.current) {
        const scrollContainer = scrollAreaRef.current.querySelector('.mantine-ScrollArea-viewport');
        if (scrollContainer) {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversations, isConvLoading]);

  async function submitMessage({mobile,message}) {
    setIsSending(true);
    try {
      await axios.post(`/chats`, { mobile, message }).then(async (res) => {
        const conversations = await axios.get(`/chats?mobile=${mobile}`);
        setConversations(conversations.data.data);
      }).catch((err)=>setError(err))
    } catch (error) {
      setError(error);
    } finally {
      setIsSending(false);
      setMessage("")
    }
  }

  const userProfileFallback = `${name ? name?.split(" ")[0][0] : "-"}
   `;
  const conversationsCopy = conversations?.map((x) => x);
  const sortedConversations = conversationsCopy?.sort(function (a, b) {
    return a?.created_at < b?.created_at
      ? -1
      : a?.created_at > b?.created_at
      ? 1
      : 0;
  });

  function handleSubmitMessage() {
    if (message == "") return;
    const messageData = { mobile, message };
    submitMessage(messageData);
  }

  function formatMobile(mobile) {
    if (!mobile) return '';
    
    // Remove leading 1 and any non-digits
    const cleaned = mobile.replace(/^1/, '').replace(/\D/g, '');
    
    // Format as (XXX) XXX-XXXX
    return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  }

  return (
    <Card
      className="chatbox-container"
      shadow="sm"
      p={0}
      m={10}
      radius="md"
      withBorder
    >
      <div className="chatbox-content-wrapper">
        <div className="chatbox">
          <div className="chatbox-userdata">
            <div className="chatbox-user">
              <>
                {" "}
                <Avatar size="sm">{userProfileFallback}</Avatar>
                <div>
                  <h2 className="username">{name}</h2>
                  <p className="userdata">
                    {email} - {can_see_numbers ? formatMobile(mobile) : '**********'}
                  </p>
                </div>
              </>
            </div>
            <TiTimes
              style={{ color: "red", fontSize: "16px", cursor: "pointer" }}
              onClick={close}
            />
          </div>
          <ScrollArea ref={scrollAreaRef} className="conversations-wrapper">
            {!isConvLoading && !sortedConversations?.length && (
              <div className="no-messages">
                {error ? (
                  <h2>{error}</h2>
                ) : (
                  <h2>This place looks empty, send a message to begin.</h2>
                )}
              </div>
            )}
            {isConvLoading && (
              <div className="no-messages">
                <Loader />
              </div>
            )}
            {!isConvLoading && (
              <div className="conversations">
                {sortedConversations?.map((item) => {
                  if (item.received == 0) {
                    return (
                      <div className=" sender-wrapper">
                        <div className=" sender-message">
                          <p>{item?.message}</p>
                          <div
                            style={{
                              marginTop: "2px",
                              fontSize: "9px",
                              color: "white",
                            }}
                          >
                            {new Date(item?.created_at).toLocaleDateString(
                              "en-US"
                            )}{" "}
                            - {new Date(item?.created_at).getHours()}:
                            {new Date(item?.created_at).getMinutes()}
                          </div>
                        </div>
                        <Avatar size="sm">You</Avatar>
                      </div>
                    );
                  } else {
                    return (
                      <div className=" reciever-wrapper">
                        <Avatar size="sm">{userProfileFallback}</Avatar>
                        <div className=" reciever-message">
                          <p>{item.message}</p>
                          <div
                            style={{
                              marginTop: "2px",
                              fontSize: "9px",
                            }}
                          >
                            {new Date(item?.created_at).toLocaleDateString(
                              "en-US"
                            )}{" "}
                            - {new Date(item?.created_at).getHours()}:
                            {new Date(item?.created_at).getMinutes()}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </ScrollArea>
          <div className="message-input-wrapper">
            <Textarea
              variant="unstyled"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              className="message-input"
            />
            <Button onClick={handleSubmitMessage} loading={isSending}>
  
                <MdSend className="text-white w-4 h-4" />
       
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
